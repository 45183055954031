import {combineReducers} from 'redux';
import LogoContainer from './LogoContainer';
import MainMenu from './MainMenu';
import Navigation from './Navigation';

const reducers = combineReducers({
    logoContainer: LogoContainer,
    mainMenu: MainMenu,
    navigation: Navigation
});

export default reducers;
