import {LOGO_CONTAINER_MOUSEENTER, LOGO_CONTAINER_MOUSELEAVE} from "../../config/actions";

export const logoContainerMouseenter = () => {
    return {
        type: LOGO_CONTAINER_MOUSEENTER
    };
};

export const logoContainerMouseleave = () => {
    return {
        type: LOGO_CONTAINER_MOUSELEAVE
    };
};
