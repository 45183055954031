import {MAIN_MENU_ITEM_MOUSEENTER, MAIN_MENU_ITEM_MOUSELEAVE} from "../../config/actions";

export const mainMenuItemMouseenter = (itemKey) => {
    return {
        type: MAIN_MENU_ITEM_MOUSEENTER,
        itemKey
    };
};

export const mainMenuItemMouseleave = (itemKey) => {
    return {
        type: MAIN_MENU_ITEM_MOUSELEAVE,
        itemKey
    };
};
