import React from 'react';
import MainMenuItem from "./MainMenuItem";
import {Translate} from "react-localize-redux";
import {connect} from "react-redux";

class MainMenu extends React.Component {
    render() {
        return (
            <div className={`MainMenu${this.props.white ? ' white' : ''}`} id={this.props.interestingForBottomLine ? 'mainMenu' : ''} style={this.props.interestingForBottomLine && this.props.navigation.careAboutBottomLine && this.props.navigation.careAboutBottomLine.mainMenu ? {position: 'absolute', top: this.props.navigation.careAboutBottomLine.mainMenu, bottom: 'unset'} : {}}>
                <MainMenuItem itemKey={0} to="/">
                    <span className="d-none d-lg-inline-block">
                        <Translate id="mainMenu.agentur.long"/>
                    </span>
                    <span className="d-inline-block d-lg-none">
                        <Translate id="mainMenu.agentur.short"/>
                    </span>
                </MainMenuItem><br className="d-none d-sm-block d-md-none d-lg-block"/>
                <MainMenuItem itemKey={1} to="/arbeiten">
                    <span className="d-none d-lg-inline-block">
                        <Translate id="mainMenu.work.long"/>
                    </span>
                    <span className="d-inline-block d-lg-none">
                        <Translate id="mainMenu.work.short"/>
                    </span>
                </MainMenuItem><br className="d-none d-sm-block d-md-none d-lg-block"/>
                <MainMenuItem itemKey={2} to="/kontakt">
                    <span className="d-none d-lg-inline-block">
                        <Translate id="mainMenu.contact.long"/>
                    </span>
                    <span className="d-inline-block d-lg-none">
                        <Translate id="mainMenu.contact.short"/>
                    </span>
                </MainMenuItem>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    navigation: state.navigation
});

export default connect(mapStateToProps, {})(MainMenu);
