import React from 'react';

class FullSizeText extends React.Component {

    state = {
        outerWidth: 1000,
        outerHeight: 1000,
        fontSize: 1,
        currentDirection: 'up',
        widthOnly: false
    };
    containerRef = React.createRef();
    innerRef = React.createRef();
    browserResizeTimeout;
    isRunning = false;
    stopCurrentRun = false;
    waitInterval;

    componentDidMount() {
        if (this.props.widthOnly) {
            this.setState({widthOnly: true});
        }
        window.addEventListener("resize", this.onBrowserResize);
        this.startCalculateFontSize();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onBrowserResize);
    }

    onBrowserResize = () => {
        if (this.browserResizeTimeout) window.clearTimeout(this.browserResizeTimeout);
        this.browserResizeTimeout = window.setTimeout(this.startCalculateFontSize, 1500);
    };

    startCalculateFontSize = () => {
        if(this.containerRef.current) {
            let newOuterWidth = this.containerRef.current.offsetWidth;
            let newHeightPercentage = this.calcHeightPercentage();
            if(newOuterWidth !== this.state.outerWidth || (!this.state.widthOnly && newHeightPercentage > 1)) {
                this.setState({
                    outerWidth: newOuterWidth,
                    outerHeight: this.containerRef.current.offsetHeight
                });
                if (this.calcWidthPercentage() < 1 && (this.state.widthOnly || newHeightPercentage < 1)) {
                    this.setState({currentDirection: 'up'});
                } else {
                    this.setState({currentDirection: 'down'});
                }
                this.tryChangeFontSize();
            }
        }
    };

    tryChangeFontSize = () => {
        if (this.isRunning) {
            this.stopCurrentRun = true;
            if (this.waitInterval) clearInterval(this.waitInterval);
            this.waitInterval = window.setInterval(this.tryChangeFontSize, 50);
        } else {
            if (this.waitInterval) clearInterval(this.waitInterval);
            this.isRunning = true;
            this.changeFontSize();
        }
    };

    changeFontSize = (isLastStep) => {
        if (this.stopCurrentRun) {
            this.isRunning = false;
            this.stopCurrentRun = false;
        } else {
            let currentWidthPercentage = this.calcWidthPercentage();
            let currentHeightPercentage = this.calcHeightPercentage();
            let changePercentage = this.state.widthOnly ? Math.abs(currentWidthPercentage - 1) : (Math.abs(currentWidthPercentage - 1) < Math.abs(currentHeightPercentage - 1) ? Math.abs(currentWidthPercentage - 1) : Math.abs(currentHeightPercentage - 1));
            if (!isLastStep) {
                this.setState({
                    fontSize: Math.max(this.state.fontSize + (this.state.currentDirection === 'up' ? (Math.max(10 * changePercentage, 0.1)) : (Math.min(-10 * changePercentage, -0.1))), 1)
                });

                if (this.state.currentDirection === 'up' && currentWidthPercentage < 0.95 && (this.state.widthOnly || currentHeightPercentage < 1)) {
                    window.setTimeout(this.changeFontSize, 0);
                } else if (this.state.currentDirection === 'down' && (currentWidthPercentage > 0.95 || (!this.state.widthOnly && currentHeightPercentage > 1))) {
                    window.setTimeout(this.changeFontSize, 0);
                } else {
                    window.setTimeout(() => this.changeFontSize(true), 0);
                }
            } else {
                let newFontSize = Math.max(this.state.fontSize + (this.state.currentDirection === 'up' ? (Math.max(-10 * changePercentage, 0.1)) : (Math.min(10 * changePercentage, -0.1))), 1);
                this.setState({
                    fontSize: newFontSize
                });
                if(currentWidthPercentage > 1) {
                    this.setState({currentDirection: 'down'});
                    window.setTimeout(() => this.changeFontSize(true), 0);
                }
                this.isRunning = false;
                this.stopCurrentRun = false;
                if(this.props.onFinish) {
                    this.props.onFinish(newFontSize);
                }
            }
        }
    };

    calcWidthPercentage = () => {
        return this.getInnerWidth() / this.state.outerWidth;
    };

    calcHeightPercentage = () => {
        return this.getInnerHeight() / this.state.outerHeight;
    };

    getInnerWidth = () => {
        if(this.innerRef.current) {
            return this.innerRef.current.offsetWidth
        }
        return 0;
    };
    getInnerHeight = () => {
        if(this.innerRef.current) {
            return this.innerRef.current.offsetHeight
        }
        return 0;
    };

    render() {
        return (
            <div className={this.props.className}>
                <div className="FullSizeText" ref={this.containerRef}>
                    <div className="FullSizeText-text" style={{fontSize: this.state.fontSize}} ref={this.innerRef}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}

export default FullSizeText;
