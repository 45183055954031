import React from 'react';
import {Translate} from "react-localize-redux";
import brlo1Img from '../../gfx/work/brlo/brlo1.jpg';
import brlo2Img from '../../gfx/work/brlo/brlo2.jpg';
import brlo3Img from '../../gfx/work/brlo/brlo3.jpg';
import brlo4Img from '../../gfx/work/brlo/brlo4.jpg';
import brlo5Img from '../../gfx/work/brlo/brlo5.jpg';
import zeckertImg from '../../gfx/work/brlo/zeckert.png';
import quoteLeftDoubleImg from '../../gfx/quote-left-double.svg';
import quoteRightSingleImg from '../../gfx/quote-right-single.svg';
import WorkProjectFirstElement from "../../elements/WorkProjectFirstElement";
import ProjectPreview from "../../elements/ProjectPreview";
import ProjectPreviewContainer from "../../elements/ProjectPreviewContainer";

class Brlo extends React.Component {
    render() {
        return (
            <div className="brlo workProject container-semi-fluid">
                <div className="row workProject-header flex-wrap">
                    <div className="col-11 offset-0 col-sm-4 offset-sm-3 col-md-6 offset-md-0 col-lg-4 offset-lg-4 client-col">
                        <h5>
                            <Translate id="work.client"/>
                        </h5>
                        <p>
                            <Translate id="work.brlo.client" options={{renderInnerHtml: true}}/>
                        </p>
                        <p className="partners">
                            <Translate id="work.brlo.partners"/>
                        </p>
                    </div>
                    <div className="col-12 d-sm-none spacer client-project-spacer"/>
                    <div className="col-11 col-sm-4 offset-sm-1 col-md-6 offset-md-0 col-lg-4 offset-lg-0 project-col">
                        <h5>
                            <Translate id="work.project"/>
                        </h5>
                        <p>
                            <Translate id="work.brlo.project"/>
                        </p>
                    </div>
                </div>
                <div className="row workProject-content flex-wrap">
                    <WorkProjectFirstElement className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4">
                        <img src={brlo1Img} alt=""/>
                    </WorkProjectFirstElement>
                    <div className="col-12 spacer"/>
                    <div className="col-12 col-sm-9 offset-sm-3 col-md-12 offset-md-0">
                        <div className="row">
                            <div className="col-6 offset-0 col-lg-4 offset-lg-4">
                                <img src={brlo2Img} alt=""/>
                            </div>
                            <div className="col-6 col-lg-4">
                                <img src={brlo3Img} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-lg-6 offset-lg-6 text">
                        <div className="row">
                            <div className="col-12 col-sm-6 offset-sm-6 col-md-12 offset-md-0 text-col">
                                <Translate id="work.brlo.text1" options={{renderInnerHtml: true}}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4">
                        <img src={brlo4Img} alt=""/>
                    </div>
                    <div className="col-12 col-md-9 offset-md-3 col-lg-12 offset-lg-0 quote-col">
                        <div className="row flex-wrap quote">
                            <div className="col-3 col-sm-1 offset-sm-3 col-md-3 offset-md-0 col-lg-1 offset-lg-4 quote-left">
                                <img src={quoteLeftDoubleImg} alt=""/>
                            </div>
                            <div className="col-6 offset-3 d-sm-none d-md-block d-lg-none quote-right-smaller-container">
                                <div className="quote-right-smaller">
                                    <div className="row">
                                        <div className="col-5">
                                            <div className="quote-right-left-container">
                                                <img className="quote-right-single-img" src={quoteRightSingleImg}
                                                     alt=""/>
                                                <img className="quote-author-img" src={zeckertImg} alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-5 offset-2">
                                            <div className="quote-right-right-container">
                                                <img className="quote-right-single-img" src={quoteRightSingleImg}
                                                     alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4 offset-sm-1 col-md-12 offset-md-0 col-lg-5 quote-text">
                                <p>
                                    <Translate id="work.brlo.quote.text" options={{renderInnerHtml: true}}/>
                                </p>
                                <div className="quote-author-info">
                                    <div className="quote-author-name">
                                        <Translate id="work.brlo.quote.name"/>
                                    </div>
                                    <div className="quote-author-role">
                                        <Translate id="work.brlo.quote.role"/>
                                    </div>
                                </div>
                            </div>
                            <div className="d-none d-sm-block d-md-none d-lg-block offset-sm-1 offset-md-0 col-1 quote-right-bigger-col-left">
                                <div className="quote-right-left-bigger-container">
                                    <div className="quote-right-left-bigger">
                                        <img className="quote-right-single-img" src={quoteRightSingleImg}
                                             alt=""/>
                                        <img className="quote-author-img" src={zeckertImg} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="d-none d-sm-block d-md-none d-lg-block col-1 quote-right-bigger-col-right">
                                <div className="quote-right-right-bigger-container">
                                    <div className="quote-right-right-bigger">
                                        <img className="quote-right-single-img" src={quoteRightSingleImg}
                                             alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4">
                        <img src={brlo5Img} alt=""/>
                    </div>
                    <div
                        className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4 credits">
                        <strong>
                            <Translate id="work.photoCredit"/>&nbsp;
                        </strong>
                        <Translate id="work.brlo.photoCredit"/>
                    </div>
                </div>
                <ProjectPreviewContainer>
                    <ProjectPreview projectKey="energie" link="/arbeiten/energiewende"/>
                    <ProjectPreview projectKey="schlemmer" link="/arbeiten/schlemmer-variete"/>
                    <ProjectPreview projectKey="momo" link="/arbeiten/momo"/>
                </ProjectPreviewContainer>
            </div>
        )
    }
}

export default Brlo;
