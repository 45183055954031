import React from 'react';
import {Translate} from "react-localize-redux";

import edekaImg from '../gfx/work/overview/edeka.jpg';
import schlemmerImg from '../gfx/work/overview/schlemmer.jpg';
import oneZeroImg from '../gfx/work/overview/oneZero.jpg';
import momoImg from '../gfx/work/overview/momo.jpg';
import mandyImg from '../gfx/work/overview/mandy.jpg';
import brloImg from '../gfx/work/overview/brlo.jpg';
import kukoImg from '../gfx/work/overview/kuko.jpg';
import energieImg from '../gfx/work/overview/energie.jpg';
import {Link} from "react-router-dom";

class ProjectPreview extends React.Component {

    images = {
        "edeka": edekaImg,
        "schlemmer": schlemmerImg,
        "oneZero": oneZeroImg,
        "momo": momoImg,
        "mandy": mandyImg,
        "brlo": brloImg,
        "kuko": kukoImg,
        "energie": energieImg
    };

    render() {
        return (
            <Link className="project-preview-col col-4" to={this.props.link}>
                <h4 className="d-none d-lg-block">
                    <Translate id={`work.overview.current.${this.props.projectKey}.title`}/>
                </h4>
                <p className="d-none d-lg-block">
                    <Translate id={`work.overview.current.${this.props.projectKey}.subTitle`}/>
                </p>
                <img src={this.images[this.props.projectKey]} alt=""/>
            </Link>
        )
    }
}

export default ProjectPreview;
