import React from 'react';
import {connect} from "react-redux";
import {Link} from 'react-router-dom';
import {
    mainMenuItemMouseenter,
    mainMenuItemMouseleave
} from "../store/actions";
import arrowBlack from "../gfx/arrow-black.svg";

class MainMenuItem extends React.Component {

    itemRef = React.createRef();

    componentDidMount() {
        this.itemRef.current.addEventListener('mouseenter', this.addHoverClass);
        this.itemRef.current.addEventListener('mouseleave', this.removeHoverClass);
    }

    componentWillUnmount() {
        this.itemRef.current.removeEventListener('mouseenter', this.addHoverClass);
        this.itemRef.current.removeEventListener('mouseleave', this.removeHoverClass);
    }

    addHoverClass = () => {
        this.props.mainMenuItemMouseenter(this.props.itemKey);
    };

    removeHoverClass = () => {
        this.props.mainMenuItemMouseleave(this.props.itemKey);
    };

    render() {
        return (
            <div className={`MainMenuItem${this.props.mainMenu.activeItemKey === this.props.itemKey ? ' active' : ''}${this.props.mainMenu.hoverItemKey === this.props.itemKey ? ' hover' : ''}`} ref={this.itemRef}>
                <div className="mainMenu-item-overlay">
                    <div className="overlay-container">
                        <div className="yellow"/>
                        <svg version="1.1" id="Ebene_1" x="0px" y="0px"
                             viewBox="0 0 32.65 54.09" style={{enableBackground: 'new 0 0 32.65 54.09;'}}>
                            <path className="st0" d="M7.07,11.04l25.58-0.02v31h-7.44L7.07,11.04z" style={{fill: 'yellow'}}/>
                            <line className="st1" x1="31.92" y1="53.52" x2="0.92" y2="0.52" style={{fill: 'none', stroke: '#000000', strokeWidth: 2}}/>
                        </svg>
                    </div>
                    <div className="title">
                        <Link to={this.props.to}>{this.props.children}</Link>
                    </div>
                </div>
                <Link to={this.props.to}>{this.props.children}</Link>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    mainMenu: state.mainMenu
});

export default connect(mapStateToProps, {
    mainMenuItemMouseenter, mainMenuItemMouseleave
})(MainMenuItem);
