import React from 'react';
import FullSizeText from "./FullSizeText";
import {connect} from "react-redux";

class HeaderClaim extends React.Component {
    render() {
        return (
            <div
                className={`row HeaderClaim${(this.props.navigation.currentRoute && this.props.navigation.currentRoute.headerClaimKey) ? (this.props.navigation.currentRoute.headerClaimKey === this.props.itemKey ? (this.props.navigation.lastRoute ? ' in' : ' initial') : ((this.props.navigation.lastRoute && this.props.navigation.lastRoute.headerClaimKey && this.props.navigation.lastRoute.headerClaimKey === this.props.itemKey && !this.props.onlyIn) ? ' out' : '')) : ''}`}>
                <FullSizeText onFinish={this.props.onFinish ? this.props.onFinish : null} className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4 headerClaim-container">
                    {this.props.children}
                </FullSizeText>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    navigation: state.navigation
});

export default connect(mapStateToProps, {})(HeaderClaim);
