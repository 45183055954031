import React from 'react';
import {Translate} from "react-localize-redux";
import edeka1Img from '../../gfx/work/edeka/edeka1.jpg';
import edeka2Img from '../../gfx/work/edeka/edeka2.jpg';
import edeka3Img from '../../gfx/work/edeka/edeka3.jpg';
import edeka4Img from '../../gfx/work/edeka/edeka4.jpg';
import edeka5Img from '../../gfx/work/edeka/edeka5.jpg';
import edeka6Img from '../../gfx/work/edeka/edeka6.jpg';
import edeka7Img from '../../gfx/work/edeka/edeka7.jpg';
import edeka8Img from '../../gfx/work/edeka/edeka8.jpg';
import WorkProjectFirstElement from "../../elements/WorkProjectFirstElement";
import ProjectPreview from "../../elements/ProjectPreview";
import ProjectPreviewContainer from "../../elements/ProjectPreviewContainer";

class Edeka extends React.Component {
    render() {
        return null; /*(
            <div className="Edeka workProject container-semi-fluid">
                <div className="row workProject-header flex-wrap">
                    <div
                        className="col-11 offset-0 col-sm-4 offset-sm-3 col-md-6 offset-md-0 col-lg-4 offset-lg-4 client-col">
                        <h5>
                            <Translate id="work.client"/>
                        </h5>
                        <p>
                            <Translate id="work.edeka.client"/>
                        </p>
                        <p className="partners">
                            <Translate id="work.edeka.partners"/>
                        </p>
                    </div>
                    <div className="col-12 d-sm-none spacer client-project-spacer"/>
                    <div className="col-11 col-sm-4 offset-sm-1 col-md-6 offset-md-0 col-lg-4 offset-lg-0 project-col">
                        <h5>
                            <Translate id="work.project"/>
                        </h5>
                        <p>
                            <Translate id="work.edeka.project"/>
                        </p>
                    </div>
                </div>
                <div className="row workProject-content flex-wrap">
                    <WorkProjectFirstElement className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4">
                        <img src={edeka1Img} alt=""/>
                    </WorkProjectFirstElement>
                    <div className="col-12 spacer"/>
                    <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4">
                        <img src={edeka2Img} alt=""/>
                    </div>
                    <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-lg-6 offset-lg-6 text">
                        <div className="row">
                            <div className="col-12 col-sm-6 offset-sm-6 col-md-12 offset-md-0 text-col">
                                <Translate id="work.edeka.text1" options={{renderInnerHtml: true}}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4">
                        <img src={edeka3Img} alt=""/>
                    </div>
                    <div className="col-12 spacer"/>
                    <div className="col-12 col-sm-9 offset-sm-3 col-md-12 offset-md-0">
                        <div className="row">
                            <div className="col-6 offset-0 col-lg-4 offset-lg-4">
                                <img src={edeka4Img} alt=""/>
                            </div>
                            <div className="col-6 col-lg-4">
                                <img src={edeka5Img} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-lg-6 offset-lg-6 text">
                        <div className="row">
                            <div className="col-12 col-sm-6 offset-sm-6 col-md-12 offset-md-0 text-col">
                                <Translate id="work.edeka.text2" options={{renderInnerHtml: true}}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4">
                        <img src={edeka6Img} alt=""/>
                    </div>
                    <div className="col-12 spacer"/>
                    <div className="col-12 col-sm-9 offset-sm-3 col-md-12 offset-md-0">
                        <div className="row">
                            <div className="col-6 offset-0 col-lg-4 offset-lg-4">
                                <img src={edeka7Img} alt=""/>
                            </div>
                            <div className="col-6 col-lg-4">
                                <img src={edeka8Img} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
                <ProjectPreviewContainer>
                    <ProjectPreview projectKey="mandy" link="/arbeiten/mandy-robotersimulation"/>
                    <ProjectPreview projectKey="brlo" link="/arbeiten/brlo-brwhouse"/>
                    <ProjectPreview projectKey="momo" link="/arbeiten/momo"/>
                </ProjectPreviewContainer>
            </div>
        )*/
    }
}

export default Edeka;
