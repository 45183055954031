import routes from '../../config/routes';
import {
    MAIN_MENU_SET_ACTIVE_ITEM, NAVIGATION_SET_CARE_ABOUT_BOTTOM_LINE, NAVIGATION_SET_CARE_ABOUT_BOTTOM_LINE_ORIGINALS,
    NAVIGATION_SET_CONTENT,
    NAVIGATION_SET_IS_SCROLLED, NAVIGATION_SET_OVERLAY_OPACITY,
    NAVIGATION_SET_ROUTE, NAVIGATION_SET_SHUTTER_DIRECTION, NAVIGATION_SET_WORK_PROJECT_FIRST_ELEMENT_TOP
} from "../../config/actions";

export const navigate = (path) => {
    return (dispatch, getState) => {
        let route = routes[path] ? routes[path] : routes["/"];
        dispatch({
            type: MAIN_MENU_SET_ACTIVE_ITEM,
            itemKey: route.mainMenuKey !== null ? route.mainMenuKey : null
        });
        if (getState().navigation.isScrolled  && getState().navigation.currentRoute && (!getState().navigation.currentRoute.backToWorkButton || "/arbeiten" !== path)) {
            dispatch({
                type: NAVIGATION_SET_SHUTTER_DIRECTION,
                shutterDirection: 'in'
            });
            window.setTimeout(() => {
                window.scrollTo(0, 0);
                dispatch({
                    type: NAVIGATION_SET_ROUTE,
                    route
                });
                dispatch({
                    type: NAVIGATION_SET_CONTENT,
                    content: route.content
                });
                window.setTimeout(() => {
                    dispatch({
                        type: NAVIGATION_SET_SHUTTER_DIRECTION,
                        shutterDirection: 'out'
                    });
                }, 600);
            }, 1040);
        } else {
            if(getState().navigation.currentRoute && getState().navigation.currentRoute.backToWorkButton) {
                window.scrollTo(0, window.innerHeight + 3);
            }
            dispatch({
                type: NAVIGATION_SET_CONTENT,
                content: route.content
            });
            dispatch({
                type: NAVIGATION_SET_ROUTE,
                route
            });
        }
    }

};

export const isScrolled = (isScrolled) => {
    return {
        type: NAVIGATION_SET_IS_SCROLLED,
        isScrolled
    };
};

export const setOverlayOpacity = (overlayOpacity) => {
    return {
        type: NAVIGATION_SET_OVERLAY_OPACITY,
        overlayOpacity
    };
};

export const setWorkProjectFirstElementTop = (workProjectFirstElementTop) => {
    return {
        type: NAVIGATION_SET_WORK_PROJECT_FIRST_ELEMENT_TOP,
        workProjectFirstElementTop
    }
};

export const setCareAboutBottomLine = (careAboutBottomLine) => {
    return {
        type: NAVIGATION_SET_CARE_ABOUT_BOTTOM_LINE,
        careAboutBottomLine
    }
};

export const setCareAboutBottomLineOriginals = (careAboutBottomLineOriginals) => {
    return {
        type: NAVIGATION_SET_CARE_ABOUT_BOTTOM_LINE_ORIGINALS,
        careAboutBottomLineOriginals
    }
};

