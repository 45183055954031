export const LOGO_CONTAINER_MOUSEENTER = 'LOGO_CONTAINER_MOUSEENTER';
export const LOGO_CONTAINER_MOUSELEAVE = 'LOGO_CONTAINER_MOUSELEAVE';

export const MAIN_MENU_ITEM_MOUSEENTER = 'MAIN_MENU_ITEM_MOUSEENTER';
export const MAIN_MENU_ITEM_MOUSELEAVE = 'MAIN_MENU_ITEM_MOUSELEAVE';
export const MAIN_MENU_SET_ACTIVE_ITEM = 'MAIN_MENU_SET_ACTIVE_ITEM';

export const NAVIGATION_SET_ROUTE = 'NAVIGATION_SET_ROUTE';
export const NAVIGATION_SET_CONTENT = 'NAVIGATION_SET_CONTENT';
export const NAVIGATION_SET_IS_SCROLLED = 'NAVIGATION_SET_IS_SCROLLED';
export const NAVIGATION_SET_OVERLAY_OPACITY = 'NAVIGATION_SET_OVERLAY_OPACITY';
export const NAVIGATION_SET_SHUTTER_DIRECTION = 'NAVIGATION_SET_SHUTTER_DIRECTION';
export const NAVIGATION_SET_WORK_PROJECT_FIRST_ELEMENT_TOP = 'NAVIGATION_SET_WORK_PROJECT_FIRST_ELEMENT_TOP';
export const NAVIGATION_SET_CARE_ABOUT_BOTTOM_LINE = 'NAVIGATION_SET_CARE_ABOUT_BOTTOM_LINE';
export const NAVIGATION_SET_CARE_ABOUT_BOTTOM_LINE_ORIGINALS = 'NAVIGATION_SET_CARE_ABOUT_BOTTOM_LINE_ORIGINALS';
