import React from 'react';
import {Translate} from "react-localize-redux";

class Privacy extends React.Component {
    render() {
        return (
            <div className="Privacy container-semi-fluid">
                <div className="row">
                    <div className="col-12 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4">
                        <Translate id="privacy.intro" options={{renderInnerHtml: true}}/>
                        <h2><Translate id="privacy.title"/></h2>
                        <div className="part">
                            <h4><Translate id="privacy.part1.title"/></h4>
                            <div className="text">
                                <Translate id="privacy.part1.text" options={{renderInnerHtml: true}}/>
                            </div>
                        </div>
                        <div className="part">
                            <h4><Translate id="privacy.part2.title"/></h4>
                            <div className="text">
                                <Translate id="privacy.part2.text" options={{renderInnerHtml: true}}/>
                            </div>
                        </div>
                        <div className="part">
                            <h4><Translate id="privacy.part3.title"/></h4>
                            <div className="text">
                                <Translate id="privacy.part3.text" options={{renderInnerHtml: true}}/>
                            </div>
                            <div className="conclusion">
                                <Translate id="privacy.part3.conclusion" options={{renderInnerHtml: true}}/>
                            </div>
                        </div>
                        <div className="footer">
                            <Translate id="privacy.footer" options={{renderInnerHtml: true}}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Privacy;
