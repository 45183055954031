import React from 'react';
import { renderToStaticMarkup } from "react-dom/server";
import '../scss/grid.scss';
import '../scss/app.scss';
import LogoContainer from "../elements/LogoContainer";
import SideMenu from "../elements/SideMenu";
import MainMenu from "../elements/MainMenu";
import {Router} from "react-router";
import {connect} from "react-redux";
import {isScrolled, navigate, setOverlayOpacity} from "../store/actions";
import HeaderClaims from "../elements/HeaderClaims";
import Agentur from "./agentur";
import Shutter from "../elements/Shutter";
import Kontakt from "./kontakt";
import FooterMenu from "../elements/FooterMenu";
import {withLocalize} from "react-localize-redux";
import globalTranslations from "../translations/global";
import Work from "./work";
import BackToWorkButton from "../elements/BackToWorkButton";
import Edeka from "./work/Edeka";
import Schlemmer from "./work/Schlemmer";
import OneZero from "./work/OneZero";
import Momo from "./work/Momo";
import ManDy from "./work/ManDy";
import Brlo from "./work/Brlo";
import Privacy from "./privacy";
import Imprint from "./imprint";
import Kuko from "./work/Kuko";
import Energie from "./work/Energie";
import {detect} from "detect-browser";

class App extends React.Component {

    constructor(props) {
        super(props);

        this.props.initialize({
            languages: [
                { name: "German", code: "de"},
                { name: "English", code: "en"}
            ],
            translations: globalTranslations,
            options: { renderToStaticMarkup }
        });
        this.props.addTranslation(globalTranslations);
    }

    state = {
        currentPath: null,
        sideMenuOpacity: 1
    };

    componentDidMount() {
        document.body.classList.add('logoTextHidden');
        this.props.navigate(this.props.history.location.pathname);
        window.addEventListener("scroll", this.handleScroll);
        //window.addEventListener("resize", this.setVh);
        this.handleScroll();
        this.setVh();
        this.unlistenHistory = this.props.history.listen((location, action) => {
            this.props.navigate(location.pathname);
            this.setState({
                currentPath: location.pathname
            });
            if(location.pathname !== "/") {
                document.body.classList.add('logoTextHidden');
            }
        });
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
        //window.removeEventListener("resize", this.setVh);
        this.unlistenHistory();
    }

    setVh = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    handleScroll = () => {
        this.calcOverlayHeaderOpacity();
        this.props.isScrolled(window.scrollY > 0);
        this.setState({
            sideMenuOpacity: Math.max(Math.min(1 - window.scrollY / window.innerHeight * 3.5, 1), 0)
        })
    };

    calcOverlayHeaderOpacity = () => {
        let blackTopElement = document.getElementById('blackTopElement');
        if(blackTopElement) {
            let btePosition = blackTopElement.getBoundingClientRect().top - window.innerHeight;
            let newOpacity;
            if(btePosition > -50) {
                newOpacity = 0;
            } else {
                newOpacity = 1;
            }
            this.props.setOverlayOpacity(newOpacity);
        } else {
            this.props.setOverlayOpacity(0);
        }
    };

    render() {

        let browser = detect();
        const isSafari = browser.name === 'safari';
        const isEdge = browser.name === 'edge';

        return (
            <div className={`App${isSafari ? ' safari' : ''}${isEdge ? ' edge' : ''}`}>
                <Router history={this.props.history}>
                    <MainMenu interestingForBottomLine/>
                    <SideMenu opacity={this.state.sideMenuOpacity}/>
                    <LogoContainer interestingForBottomLine/>
                    {this.props.navigation.currentRoute && this.props.navigation.currentRoute.backToWorkButton ? (
                        <BackToWorkButton interestingForBottomLine/>
                    ) : null}
                    <Shutter>
                        <MainMenu/>
                        <LogoContainer/>
                        <HeaderClaims onlyIn/>
                    </Shutter>
                    <div className={`bg-black-container overlay-header${this.props.navigation.overlayOpacity === 0 ? ' out' : ' in'}`}>
                        <div className="bg-black-helper">
                            <MainMenu white/>
                            <LogoContainer white/>
                        </div>
                        <HeaderClaims/>
                    </div>
                    <HeaderClaims/>
                    {this.props.navigation.currentContent ? (
                        this.props.navigation.currentContent === 'agentur' ? (
                            <Agentur/>
                        ) : this.props.navigation.currentContent === 'work' ? (
                            <Work/>
                        ) : this.props.navigation.currentContent === 'kontakt' ? (
                            <Kontakt/>
                        ) : this.props.navigation.currentContent === 'edeka' ? (
                            <Edeka/>
                        ) : this.props.navigation.currentContent === 'schlemmer' ? (
                            <Schlemmer/>
                        ) : this.props.navigation.currentContent === 'onezero' ? (
                            <OneZero/>
                        ) : this.props.navigation.currentContent === 'momo' ? (
                            <Momo/>
                        ) : this.props.navigation.currentContent === 'mandy' ? (
                            <ManDy/>
                        ) : this.props.navigation.currentContent === 'brlo' ? (
                            <Brlo/>
                        ) : this.props.navigation.currentContent === 'kuko' ? (
                            <Kuko/>
                        ) : this.props.navigation.currentContent === 'energie' ? (
                            <Energie/>
                        ) : this.props.navigation.currentContent === 'datenschutz' ? (
                            <Privacy/>
                        ) : this.props.navigation.currentContent === 'impressum' ? (
                            <Imprint/>
                        ) : null
                    ) : null}
                    <FooterMenu black={this.props.navigation.currentRoute && this.props.navigation.currentRoute.footerMenuBlack}/>
                </Router>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    navigation: state.navigation
});

export default withLocalize(connect(mapStateToProps, {
    navigate, isScrolled, setOverlayOpacity
})(App));
