import React from 'react';
import {Translate} from "react-localize-redux";
import energie1Img from '../../gfx/work/energie/energie1.jpg';
import energie2Img from '../../gfx/work/energie/energie2.jpg';
import energie3Img from '../../gfx/work/energie/energie3.jpg';
import energie4Img from '../../gfx/work/energie/energie4.jpg';
import energie5Img from '../../gfx/work/energie/energie5.jpg';
import energie6Img from '../../gfx/work/energie/energie6.jpg';
import WorkProjectFirstElement from "../../elements/WorkProjectFirstElement";
import ProjectPreview from "../../elements/ProjectPreview";
import ProjectPreviewContainer from "../../elements/ProjectPreviewContainer";

class Energie extends React.Component {
    render() {
        return (
            <div className="Energie workProject container-semi-fluid">
                <div className="row workProject-header flex-wrap">
                    <div
                        className="col-11 offset-0 col-sm-4 offset-sm-3 col-md-6 offset-md-0 col-lg-4 offset-lg-4 client-col">
                        <h5>
                            <Translate id="work.client"/>
                        </h5>
                        <p>
                            <Translate id="work.energie.client"/>
                        </p>
                        <p className="partners">
                            <Translate id="work.energie.partners"/>
                        </p>
                    </div>
                    <div className="col-12 d-sm-none spacer client-project-spacer"/>
                    <div className="col-11 col-sm-4 offset-sm-1 col-md-6 offset-md-0 col-lg-4 offset-lg-0 project-col">
                        <h5>
                            <Translate id="work.project"/>
                        </h5>
                        <p>
                            <Translate id="work.energie.project"/>
                        </p>
                    </div>
                </div>
                <div className="row workProject-content flex-wrap">
                    <WorkProjectFirstElement className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4">
                        <img src={energie1Img} alt=""/>
                    </WorkProjectFirstElement>
                    <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-lg-6 offset-lg-6 text">
                        <div className="row">
                            <div className="col-12 col-sm-6 offset-sm-6 col-md-12 offset-md-0 text-col">
                                <Translate id="work.energie.text1" options={{renderInnerHtml: true}}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4">
                        <img src={energie2Img} alt=""/>
                    </div>
                    <div className="col-12 spacer"/>
                    <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4">
                        <img src={energie3Img} alt=""/>
                    </div>
                    <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-lg-6 offset-lg-6 text">
                        <div className="row">
                            <div className="col-12 col-sm-6 offset-sm-6 col-md-12 offset-md-0 text-col">
                                <Translate id="work.energie.text2" options={{renderInnerHtml: true}}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-9 offset-sm-3 col-md-12 offset-md-0">
                        <div className="row">
                            <div className="col-6 offset-0 col-lg-4 offset-lg-4">
                                <img src={energie4Img} alt=""/>
                            </div>
                            <div className="col-6 col-lg-4">
                                <img src={energie5Img} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 spacer"/>
                    <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4">
                        <img src={energie6Img} alt=""/>
                    </div>
                </div>
                <ProjectPreviewContainer>
                    <ProjectPreview projectKey="kuko" link="/arbeiten/subbotnik"/>
                    <ProjectPreview projectKey="mandy" link="/arbeiten/mandy-robotersimulation"/>
                    <ProjectPreview projectKey="oneZero" link="/arbeiten/onezero"/>
                </ProjectPreviewContainer>
            </div>
        )
    }
}

export default Energie;
