import React from 'react';
import {Translate} from "react-localize-redux";
import Button from "./Button";
import {connect} from "react-redux";

class BackToWorkButton extends React.Component {
    render() {
        return (
            <div className="BackToWorkButton" style={this.props.interestingForBottomLine && this.props.navigation.careAboutBottomLine && this.props.navigation.careAboutBottomLine.backToWorkButton ? {position: 'absolute', top: this.props.navigation.careAboutBottomLine.backToWorkButton, bottom: 'unset'} : {top: this.props.navigation.workProjectFirstElementTop}} id={this.props.interestingForBottomLine ? 'backToWorkButton' : ''}>
                <Button asLink white to="/arbeiten" title={<Translate id="work.backToWorkButton"/>}/>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    navigation: state.navigation
});

export default connect(mapStateToProps, {})(BackToWorkButton);
