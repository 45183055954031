export default {
    "/": {
        name: "agentur",
        isMainMenu: true,
        mainMenuKey: 0,
        headerClaimKey: 1,
        content: "agentur"
    },
    "/arbeiten": {
        name: "work",
        isMainMenu: true,
        mainMenuKey: 1,
        headerClaimKey: 2,
        content: "work"
    },
    "/kontakt": {
        name: "kontakt",
        isMainMenu: true,
        mainMenuKey: 2,
        headerClaimKey: 3,
        content: "kontakt",
        footerMenuBlack: true
    },
    "/datenschutz": {
        name: "datenschutz",
        isMainMenu: false,
        content: "datenschutz"
    },
    "/impressum": {
        name: "impressum",
        isMainMenu: false,
        content: "impressum"
    },
    "/arbeiten/edeka": {
        name: "edeka",
        isMainMenu: false,
        mainMenuKey: 1,
        content: "edeka",
        backToWorkButton: true,
        noLogoInTouchPortrait: true
    },
    "/arbeiten/schlemmer-variete": {
        name: "schlemmer",
        isMainMenu: false,
        mainMenuKey: 1,
        content: "schlemmer",
        backToWorkButton: true,
        noLogoInTouchPortrait: true
    },
    "/arbeiten/onezero": {
        name: "onezero",
        isMainMenu: false,
        mainMenuKey: 1,
        content: "onezero",
        backToWorkButton: true,
        noLogoInTouchPortrait: true
    },
    "/arbeiten/momo": {
        name: "momo",
        isMainMenu: false,
        mainMenuKey: 1,
        content: "momo",
        backToWorkButton: true,
        noLogoInTouchPortrait: true
    },
    "/arbeiten/mandy-robotersimulation": {
        name: "mandy",
        isMainMenu: false,
        mainMenuKey: 1,
        content: "mandy",
        backToWorkButton: true,
        noLogoInTouchPortrait: true
    },
    "/arbeiten/brlo-brwhouse": {
        name: "brlo",
        isMainMenu: false,
        mainMenuKey: 1,
        content: "brlo",
        backToWorkButton: true,
        noLogoInTouchPortrait: true
    },
    "/arbeiten/subbotnik": {
        name: "kuko",
        isMainMenu: false,
        mainMenuKey: 1,
        content: "kuko",
        backToWorkButton: true,
        noLogoInTouchPortrait: true
    },
    "/arbeiten/energiewende": {
        name: "energie",
        isMainMenu: false,
        mainMenuKey: 1,
        content: "energie",
        backToWorkButton: true,
        noLogoInTouchPortrait: true
    }
};
