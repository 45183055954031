import React from 'react';
import Button from "../../elements/Button";
import MainMenu from "../../elements/MainMenu";
import LogoContainer from "../../elements/LogoContainer";
import {connect} from "react-redux";
import kontaktImg from '../../gfx/kontakt.jpg';
import {Translate} from "react-localize-redux";

class Kontakt extends React.Component {
    render() {
        return (
            <div className="Kontakt">
                <div className={`bg-black-container absolute${this.props.navigation.overlayOpacity === 0 ? ' out' : ' in'}`}>
                    <div className="bg-black-helper">
                        <MainMenu white/>
                        <LogoContainer white/>
                    </div>
                    <div className="container-semi-fluid">
                        <div className="row">
                            <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-11 offset-md-0 col-lg-8 offset-lg-4 col-kontakt-title">
                                <h3>
                                    <Translate id="contact.title"/>
                                </h3>
                            </div>
                        </div>
                        <div className="row contact-row flex-wrap">
                            <div className="col-12 offset-0 col-sm-3 offset-sm-3 col-md-5 offset-md-0 col-lg-4 offset-lg-4 text-center col-mail">
                                <Translate>
                                    {({ translate}) => <Button asRealLink block to={translate("contact.mail.link")} title={translate("contact.mail.title")} white/>}
                                </Translate>
                                <div className="button-info">
                                    <Translate id="contact.mail.address"/>
                                </div>
                            </div>
                            <div className="col-12 d-block d-sm-none mt-1 col-mail-phone-spacer"/>
                            <div className="col-12 col-sm-3 offset-sm-1 col-md-5 offset-md-1 col-lg-4 offset-lg-0 text-center col-phone">
                                <Translate>
                                    {({ translate}) => <Button asRealLink block to={translate("contact.phone.link")} title={translate("contact.phone.title")} white/>}
                                </Translate>
                                <div className="button-info">
                                    <Translate id="contact.phone.number"/>
                                </div>
                            </div>
                        </div>
                        <div className="row location-row flex-wrap">
                            <div className="col-auto offset-3 offset-md-0 offset-lg-4 d-none d-sm-block">
                                <Translate>
                                    {({ translate}) => <Button asRealLink block to={translate("contact.maps.link")} title={<Translate id="contact.maps.title" options={{renderInnerHtml: true}}/>} white/>}
                                </Translate>
                            </div>
                            <div className="col-auto d-none d-sm-block d-lg-none">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                            <div className="col-12 col-sm">
                                <h4>
                                    Backslash Seven GmbH<br/>
                                    Großbeerenstr. 12<br/>
                                    10963 Berlin<br/>
                                    GERMANY
                                </h4>
                            </div>
                            <div className="col-4 offset-8 d-sm-none">
                                <Translate>
                                    {({ translate}) => <Button asRealLink block to={translate("contact.maps.link")} title={<Translate id="contact.maps.title" options={{renderInnerHtml: true}}/>} white/>}
                                </Translate>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-semi-fluid">
                    <div className="row">
                        <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-11 offset-md-0 col-lg-8 offset-lg-4 col-kontakt-title">
                            <h3>
                                <Translate id="contact.title"/>
                            </h3>
                        </div>
                    </div>
                    <div className="row contact-row flex-wrap">
                        <div className="col-12 offset-0 col-sm-3 offset-sm-3 col-md-5 offset-md-0 col-lg-4 offset-lg-4 text-center col-mail">
                            <Translate>
                                {({ translate}) => <Button asRealLink block to={translate("contact.mail.link")} title={translate("contact.mail.title")}/>}
                            </Translate>
                            <div className="button-info">
                                <Translate id="contact.mail.address"/>
                            </div>
                        </div>
                        <div className="col-12 d-block d-sm-none mt-1 col-mail-phone-spacer"/>
                        <div className="col-12 col-sm-3 offset-sm-1 col-md-5 offset-md-1 col-lg-4 offset-lg-0 text-center col-phone">
                            <Translate>
                                {({ translate}) => <Button asRealLink block to={translate("contact.phone.link")} title={translate("contact.phone.title")}/>}
                            </Translate>
                            <div className="button-info">
                                <Translate id="contact.phone.number"/>
                            </div>
                        </div>
                    </div>
                    <div className="row location-row flex-wrap">
                        <div className="col-auto offset-3 offset-md-0 offset-lg-4 d-none d-sm-block">
                            <Translate>
                                {({ translate}) => <Button asRealLink block to={translate("contact.maps.link")} title={<Translate id="contact.maps.title" options={{renderInnerHtml: true}}/>}/>}
                            </Translate>
                        </div>
                        <div className="col-auto d-none d-sm-block d-lg-none">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                        <div className="col-12 col-sm">
                            <h4>
                                Backslash Seven GmbH<br/>
                                Großbeerenstr. 12<br/>
                                10963 Berlin<br/>
                                GERMANY
                            </h4>
                        </div>
                        <div className="col-4 offset-8 d-sm-none">
                            <Translate>
                                {({ translate}) => <Button asRealLink block to={translate("contact.maps.link")} title={<Translate id="contact.maps.title" options={{renderInnerHtml: true}}/>}/>}
                            </Translate>
                        </div>
                    </div>
                </div>
                <div className={`bg-black-container${this.props.navigation.overlayOpacity === 0 ? ' out' : ' in'}`} id="blackTopElement">
                    <div className="bg-black-helper">
                        <MainMenu white/>
                        <LogoContainer white/>
                    </div>
                    <div className="container-semi-fluid">
                        <div className="row image-row">
                            <div className="col-9 offset-3 col-lg-8 offset-lg-4 extend-to-right no-padding-right d-none d-sm-block d-md-none d-lg-block">
                                <img src={kontaktImg} alt=""/>
                            </div>
                            <div className="col-12 col-lg-8 offset-lg-4 extend-to-both d-block d-sm-none d-md-block d-lg-none no-padding-right no-padding-left">
                                <img src={kontaktImg} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    navigation: state.navigation
});

export default connect(mapStateToProps, {
})(Kontakt);
