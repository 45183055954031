import React from 'react';
import HeaderClaim from "./HeaderClaim";
import {Translate} from "react-localize-redux";
import {connect} from "react-redux";

class HeaderClaims extends React.Component {

    changingWordContainerRef = React.createRef();
    changingWords = [];

    onFinish = (fontSize) => {
        this.changingWords = Array.from(this.changingWordContainerRef.current.children);
        window.setTimeout(this.nextWord, 200);
    };

    nextWord = () => {
        let currentIn = this.changingWords.find((el) => el.classList.contains('in'));
        let currentInIndex = this.changingWords.indexOf(currentIn);
        if (currentInIndex + 1 < this.changingWords.length) {
            window.setTimeout(() => {
                currentIn.classList.add('out');
                currentIn.classList.remove('in');
                let nextIn = this.changingWords[currentInIndex + 1];
                nextIn.classList.add('in');
                this.nextWord();
            }, 1250);
        }
    };

    render() {
        return this.props.navigation.currentRoute && this.props.navigation.currentRoute.headerClaimKey ? (
            <div className={`container-semi-fluid HeaderClaims${this.props.extraZIndex ? ' extraZIndex' : ''}`}>
                <HeaderClaim itemKey={1} onlyIn={this.props.onlyIn} onFinish={this.onFinish}>
                    <h2 className="higherLines">
                        <Translate id="headerClaims.agentur.line1"/><br/>
                        <div className="changingWord-container" ref={this.changingWordContainerRef}>
                            <div className="changingWord in"><Translate id="headerClaims.agentur.line2.1"/></div>
                            <div className="changingWord"><Translate id="headerClaims.agentur.line2.2"/></div>
                            <div className="changingWord"><Translate id="headerClaims.agentur.line2.3"/></div>
                            <div className="changingWord"><Translate id="headerClaims.agentur.line2.4"/></div>
                            <div className="changingWord"><Translate id="headerClaims.agentur.line2.5"/></div>
                            <div className="changingWord"><Translate id="headerClaims.agentur.line2.6"/></div>
                            <div className="changingWord"><Translate id="headerClaims.agentur.line2.7"/></div>
                        </div>
                        <Translate id="headerClaims.agentur.line3" options={{renderInnerHtml: true}}/><br/>
                        <Translate id="headerClaims.agentur.line4"/>
                    </h2>
                </HeaderClaim>
                <HeaderClaim itemKey={2} onlyIn={this.props.onlyIn}>
                    <h2 className="smallerLetterSpacing">
                        <Translate id="headerClaims.work.line1"/><br/>
                        <Translate id="headerClaims.work.line2"/><br/>
                        <Translate id="headerClaims.work.line3" options={{renderInnerHtml: true}}/><br/>
                        <Translate id="headerClaims.work.line4"/>
                    </h2>
                </HeaderClaim>
                <HeaderClaim itemKey={3} onlyIn={this.props.onlyIn}>
                    <h2 className="smallerLetterSpacing">
                        <Translate id="headerClaims.contact.line1"/><br/>
                        <Translate id="headerClaims.contact.line2"/><br/>
                        <Translate id="headerClaims.contact.line3"/>
                    </h2>
                </HeaderClaim>
            </div>
        ) : null
    }
}

const mapStateToProps = state => ({
    navigation: state.navigation
});

export default connect(mapStateToProps, {})(HeaderClaims);
