import React from 'react';
import FullSizeText from "../../elements/FullSizeText";
import JobOffer from "./JobOffer";
import {Translate} from "react-localize-redux";

class Jobs extends React.Component {
    render() {
        return (
            <div className="Jobs">
                <div className="container-semi-fluid">
                    <div className="row">
                        <FullSizeText className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4" widthOnly>
                            <h3 className="claim">
                                <Translate id="agentur.jobs.title" options={{renderInnerHtml: true}}/>
                            </h3>
                        </FullSizeText>
                    </div>
                    <div className="row intro">
                        <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4">
                            <p>
                                <Translate id="agentur.jobs.text" options={{renderInnerHtml: true}}/>
                            </p>
                        </div>
                    </div>
                    <div className="row offers">
                        <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-6 offset-lg-4">
                            <JobOffer shortTitle={<Translate id="agentur.jobs.offers.account.title.short"/>} longTitle={<Translate id="agentur.jobs.offers.account.title.long"/>}>
                                <Translate id="agentur.jobs.offers.account.text" options={{renderInnerHtml: true}}/>
                            </JobOffer>
                            <JobOffer shortTitle={<Translate id="agentur.jobs.offers.backend.title.short"/>} longTitle={<Translate id="agentur.jobs.offers.backend.title.long"/>}>
                                <Translate id="agentur.jobs.offers.backend.text" options={{renderInnerHtml: true}}/>
                            </JobOffer>
                            <JobOffer shortTitle={<Translate id="agentur.jobs.offers.text.title.short"/>} longTitle={<Translate id="agentur.jobs.offers.text.title.long"/>}>
                                <Translate id="agentur.jobs.offers.text.text" options={{renderInnerHtml: true}}/>
                            </JobOffer>
                            <JobOffer title={<Translate id="agentur.jobs.offers.initiativ.title"/>} white>
                                <Translate id="agentur.jobs.offers.initiativ.text" options={{renderInnerHtml: true}}/>
                            </JobOffer>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Jobs;
