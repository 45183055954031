import React from 'react';
import pascalPic from '../../gfx/agentur/pascal.jpg';
import volkramPic from '../../gfx/agentur/volkram.jpg';
import koljaPic from '../../gfx/agentur/kolja.jpg';
import janaPic from '../../gfx/agentur/jana.jpg';
import aeniasPic from '../../gfx/agentur/aenias.jpg';
import chrisPic from '../../gfx/agentur/chris.jpg';
import nilsPic from '../../gfx/agentur/nils.jpg';
import marlouPic from '../../gfx/agentur/marlou.jpg';
import {Translate} from "react-localize-redux";

class Persons extends React.Component {
    render() {
        return (
            <div className="Persons" style={{marginTop: this.props.marginTop ? this.props.marginTop : 0}}>
                <div className="persons-black-bg-helper d-block d-sm-none"/>
                <div className="container-semi-fluid">
                    <div className="row">
                        <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4">
                            <div className="row flex-wrap">
                                <div className="col-6 person">
                                    <img src={pascalPic} alt={<Translate id="agentur.crew.pascal.name"/>}/>
                                    <h4>
                                        <Translate id="agentur.crew.pascal.name"/>
                                    </h4>
                                    <p>
                                        <Translate id="agentur.crew.pascal.job"/>
                                    </p>
                                </div>
                                <div className="col-6 person">
                                    <img src={volkramPic} alt={<Translate id="agentur.crew.volkram.name"/>}/>
                                    <h4>
                                        <Translate id="agentur.crew.volkram.name"/>
                                    </h4>
                                    <p>
                                        <Translate id="agentur.crew.volkram.job"/>
                                    </p>
                                </div>
                                {/*<div className="col-6 person">
                                    <img src={koljaPic} alt={<Translate id="agentur.crew.kolja.name"/>}/>
                                    <h4>
                                        <Translate id="agentur.crew.kolja.name"/>
                                    </h4>
                                    <p>
                                        <Translate id="agentur.crew.kolja.job"/>
                                    </p>
                                </div>*/}
                                <div className="col-6 person">
                                    <img src={janaPic} alt={<Translate id="agentur.crew.jana.name"/>}/>
                                    <h4>
                                        <Translate id="agentur.crew.jana.name"/>
                                    </h4>
                                    <p>
                                        <Translate id="agentur.crew.jana.job"/>
                                    </p>
                                </div>
                                <div className="col-6 person">
                                    <img src={aeniasPic} alt={<Translate id="agentur.crew.aenias.name"/>}/>
                                    <h4>
                                        <Translate id="agentur.crew.aenias.name"/>
                                    </h4>
                                    <p>
                                        <Translate id="agentur.crew.aenias.job"/>
                                    </p>
                                </div>
                                <div className="col-6 person">
                                    <img src={chrisPic} alt={<Translate id="agentur.crew.chris.name"/>}/>
                                    <h4>
                                        <Translate id="agentur.crew.chris.name"/>
                                    </h4>
                                    <p>
                                        <Translate id="agentur.crew.chris.job"/>
                                    </p>
                                </div>
                                <div className="col-6 person">
                                    <img src={nilsPic} alt={<Translate id="agentur.crew.nils.name"/>}/>
                                    <h4>
                                        <Translate id="agentur.crew.nils.name"/>
                                    </h4>
                                    <p>
                                        <Translate id="agentur.crew.nils.job"/>
                                    </p>
                                </div>
                                {/*<div className="col-6 person">
                                    <img src={marlouPic} alt={<Translate id="agentur.crew.marlou.name"/>}/>
                                    <h4>
                                        <Translate id="agentur.crew.marlou.name"/>
                                    </h4>
                                    <p>
                                        <Translate id="agentur.crew.marlou.job"/>
                                    </p>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Persons;
