import React from 'react';
import {Translate} from "react-localize-redux";
import kuko1Img from '../../gfx/work/kuko/kuko1.jpg';
import kuko2Img from '../../gfx/work/kuko/kuko2.jpg';
import kuko3Img from '../../gfx/work/kuko/kuko3.jpg';
import kuko4Img from '../../gfx/work/kuko/kuko4.jpg';
import kuko5Img from '../../gfx/work/kuko/kuko5.jpg';
import kuko6Img from '../../gfx/work/kuko/kuko6.jpg';
import kuko7Img from '../../gfx/work/kuko/kuko7.jpg';
import WorkProjectFirstElement from "../../elements/WorkProjectFirstElement";
import ProjectPreview from "../../elements/ProjectPreview";
import ProjectPreviewContainer from "../../elements/ProjectPreviewContainer";

class Kuko extends React.Component {
    render() {
        return (
            <div className="Kuko workProject container-semi-fluid">
                <div className="row workProject-header flex-wrap">
                    <div
                        className="col-11 offset-0 col-sm-4 offset-sm-3 col-md-6 offset-md-0 col-lg-4 offset-lg-4 client-col">
                        <h5>
                            <Translate id="work.client"/>
                        </h5>
                        <p>
                            <Translate id="work.kuko.client"/>
                        </p>
                    </div>
                    <div className="col-12 d-sm-none spacer client-project-spacer"/>
                    <div className="col-11 col-sm-4 offset-sm-1 col-md-6 offset-md-0 col-lg-4 offset-lg-0 project-col">
                        <h5>
                            <Translate id="work.project"/>
                        </h5>
                        <p>
                            <Translate id="work.kuko.project"/>
                        </p>
                    </div>
                </div>
                <div className="row workProject-content flex-wrap">
                    <WorkProjectFirstElement className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4">
                        <img src={kuko1Img} alt=""/>
                    </WorkProjectFirstElement>
                    <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-lg-6 offset-lg-6 text">
                        <div className="row">
                            <div className="col-12 col-sm-6 offset-sm-6 col-md-12 offset-md-0 text-col">
                                <Translate id="work.kuko.text1" options={{renderInnerHtml: true}}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-9 offset-sm-3 col-md-12 offset-md-0">
                        <div className="row">
                            <div className="col-6 offset-0 col-lg-4 offset-lg-4">
                                <img src={kuko2Img} alt=""/>
                            </div>
                            <div className="col-6 col-lg-4">
                                <img src={kuko3Img} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 spacer"/>
                    <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4">
                        <img src={kuko4Img} alt=""/>
                    </div>
                    <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-lg-6 offset-lg-6 text">
                        <div className="row">
                            <div className="col-12 col-sm-6 offset-sm-6 col-md-12 offset-md-0 text-col">
                                <Translate id="work.kuko.text2" options={{renderInnerHtml: true}}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-9 offset-sm-3 col-md-12 offset-md-0">
                        <div className="row">
                            <div className="col-6 offset-0 col-lg-4 offset-lg-4">
                                <img src={kuko5Img} alt=""/>
                            </div>
                            <div className="col-6 col-lg-4">
                                <img src={kuko6Img} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-lg-6 offset-lg-6 text">
                        <div className="row">
                            <div className="col-12 col-sm-6 offset-sm-6 col-md-12 offset-md-0 text-col">
                                <Translate id="work.kuko.text3" options={{renderInnerHtml: true}}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4">
                        <img src={kuko7Img} alt=""/>
                    </div>
                </div>
                <ProjectPreviewContainer>
                    {/*<ProjectPreview projectKey="edeka" link="/arbeiten/edeka"/>*/}
                    <ProjectPreview projectKey="oneZero" link="/arbeiten/onezero"/>
                    <ProjectPreview projectKey="brlo" link="/arbeiten/brlo-brwhouse"/>
                    <ProjectPreview projectKey="momo" link="/arbeiten/momo"/>
                </ProjectPreviewContainer>
            </div>
        )
    }
}

export default Kuko;
