import React from 'react';
import logoBlack from "../gfx/backslashseven-logo-black.svg";
import logoWhite from '../gfx/backslashseven-logo-white.svg';
import logoTextBlack from "../gfx/backslashseven-logo-text-black.svg";
import logoTextWhite from '../gfx/backslashseven-logo-text-white.svg';
import {connect} from "react-redux";
import {logoContainerMouseenter, logoContainerMouseleave} from "../store/actions";
import {Link} from "react-router-dom";

class LogoContainer extends React.Component {

    containerRef = React.createRef();

    componentDidMount() {
        this.containerRef.current.addEventListener('mouseenter', this.addHoverClass);
        this.containerRef.current.addEventListener('mouseleave', this.removeHoverClass);
    }

    componentWillUnmount() {
        this.containerRef.current.removeEventListener('mouseenter', this.addHoverClass);
        this.containerRef.current.removeEventListener('mouseleave', this.removeHoverClass);
    }

    addHoverClass = () => {
        this.props.logoContainerMouseenter();
    };

    removeHoverClass = () => {
        this.props.logoContainerMouseleave();
    };

    render() {
        const hover = this.props.logoContainer.hover;
        return (
            <Link className={`LogoContainer${hover ? ' hover' : ''}${this.props.navigation.currentRoute && this.props.navigation.currentRoute.noLogoInTouchPortrait ? ' d-none d-sm-inline-block d-md-none d-lg-inline-block' : ''}`} ref={this.containerRef} to="/" id={this.props.interestingForBottomLine ? 'logoContainer' : ''} style={this.props.interestingForBottomLine && this.props.navigation.careAboutBottomLine && this.props.navigation.careAboutBottomLine.logoContainer ? {position: 'absolute', top: this.props.navigation.careAboutBottomLine.logoContainer, bottom: 'unset'} : {}}>
                <img src={this.props.white ? logoWhite : logoBlack} className="logo" alt="Backslash Seven"/>
                <img src={this.props.white ? logoTextWhite : logoTextBlack} className="logo-text d-none d-lg-block" alt="Backslash Seven"/>
            </Link>
        )
    }
}

const mapStateToProps = state => ({
    logoContainer: state.logoContainer,
    navigation: state.navigation
});

export default connect(mapStateToProps, {
    logoContainerMouseenter, logoContainerMouseleave
})(LogoContainer);
