import {MAIN_MENU_ITEM_MOUSEENTER, MAIN_MENU_ITEM_MOUSELEAVE, MAIN_MENU_SET_ACTIVE_ITEM} from "../../config/actions";

const INIT_STATE = {
    activeItemKey: 0,
    hoverItemKey: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case MAIN_MENU_SET_ACTIVE_ITEM: {
            return {
                ...state,
                activeItemKey: action.itemKey
            }
        }
        case MAIN_MENU_ITEM_MOUSEENTER: {
            return {
                ...state,
                hoverItemKey: action.itemKey
            };
        }
        case MAIN_MENU_ITEM_MOUSELEAVE: {
            if (state.hoverItemKey === action.itemKey) {
                return {
                    ...state,
                    hoverItemKey: null
                };
            }
            break;
        }
        default:
            return state;
    }
}
