import React from 'react';
import {Collapse} from "react-collapse";
import Button from "../../elements/Button";

class JobOffer extends React.Component {

    state = {
        isOpen: false
    };

    render() {
        return (
            <div className="JobOffer">
                <Button title={this.props.title} shortTitle={this.props.shortTitle} longTitle={this.props.longTitle} white={this.props.white} outline={this.props.white} textLeft withArrow arrowDown={this.state.isOpen} hover={this.state.isOpen} onClick={() => this.setState({isOpen: !this.state.isOpen})}/>
                <Collapse isOpened={this.state.isOpen}>
                    <div className="jobOffer-content-container">
                        {this.props.children}
                    </div>
                </Collapse>
            </div>
        )
    }
}

export default JobOffer;
