import {LOGO_CONTAINER_MOUSEENTER, LOGO_CONTAINER_MOUSELEAVE} from "../../config/actions";

const INIT_STATE = {
    hover: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGO_CONTAINER_MOUSEENTER: {
            return {
                ...state,
                hover: true
            };
        }
        case LOGO_CONTAINER_MOUSELEAVE: {
            return {
                ...state,
                hover: false
            };
        }
        default:
            return state;
    }
}
