import React from 'react';
import {Translate} from "react-localize-redux";
import edekaImg from '../../gfx/work/overview/edeka.jpg';
import schlemmerImg from '../../gfx/work/overview/schlemmer.jpg';
import oneZeroImg from '../../gfx/work/overview/oneZero.jpg';
import momoImg from '../../gfx/work/overview/momo.jpg';
import mandyImg from '../../gfx/work/overview/mandy.jpg';
import brloImg from '../../gfx/work/overview/brlo.jpg';
import kukoImg from '../../gfx/work/overview/kuko.jpg';
import energieImg from '../../gfx/work/overview/energie.jpg';
import {Link} from "react-router-dom";

class Work extends React.Component {
    render() {
        return (
            <div className="Work container-semi-fluid">
                <div className="row">
                    <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4">
                        <h3 className="heading-with-line">
                            <Translate id="work.overview.current.title"/>
                        </h3>
                    </div>
                </div>
                <div className="row flex-wrap">
                    <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4">
                        <div className="row flex-wrap">
                            {/*<div className="col-12 col-sm-6 col-sp">
                                <Link to="/arbeiten/edeka" className="project">
                                    <div className="row">
                                        <div className="col-9">
                                            <h4>
                                                <Translate id="work.overview.current.edeka.title"/>
                                            </h4>
                                            <p>
                                                <Translate id="work.overview.current.edeka.subTitle"/>
                                            </p>
                                        </div>
                                        <div className="col-3 type">
                                            <div>
                                                <Translate id="work.overview.current.edeka.type"
                                                           options={{renderInnerHtml: true}}/>
                                            </div>
                                        </div>
                                    </div>
                                    <img src={edekaImg} alt="Edeka"/>
                                </Link>
                            </div>*/}
                            <div className="col-12 col-sm-6 col-sp">
                                <Link to="/arbeiten/subbotnik" className="project">
                                    <div className="row">
                                        <div className="col-9">
                                            <h4>
                                                <Translate id="work.overview.current.kuko.title"/>
                                            </h4>
                                            <p>
                                                <Translate id="work.overview.current.kuko.subTitle"/>
                                            </p>
                                        </div>
                                        <div className="col-3 type">
                                            <div>
                                                <Translate id="work.overview.current.kuko.type"
                                                           options={{renderInnerHtml: true}}/>
                                            </div>
                                        </div>
                                    </div>
                                    <img src={kukoImg} alt="Subbotnik"/>
                                </Link>
                            </div>
                            <div className="col-12 col-sm-6 col-sp">
                                <Link to="/arbeiten/schlemmer-variete" className="project">
                                    <div className="row">
                                        <div className="col-9">
                                            <h4>
                                                <Translate id="work.overview.current.schlemmer.title"/>
                                            </h4>
                                            <p>
                                                <Translate id="work.overview.current.schlemmer.subTitle"/>
                                            </p>
                                        </div>
                                        <div className="col-3 type">
                                            <div>
                                                <Translate id="work.overview.current.schlemmer.type"
                                                           options={{renderInnerHtml: true}}/>
                                            </div>
                                        </div>
                                    </div>
                                    <img src={schlemmerImg} alt="Schlemmer Variete"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4">
                        <div className="row flex-wrap">
                            <div className="col-12 col-sm-6 col-sp">
                                <Link to="/arbeiten/onezero" className="project">
                                    <div className="row">
                                        <div className="col-9">
                                            <h4>
                                                <Translate id="work.overview.current.oneZero.title"/>
                                            </h4>
                                            <p>
                                                <Translate id="work.overview.current.oneZero.subTitle"/>
                                            </p>
                                        </div>
                                        <div className="col-3 type">
                                            <div>
                                                <Translate id="work.overview.current.oneZero.type"
                                                           options={{renderInnerHtml: true}}/>
                                            </div>
                                        </div>
                                    </div>
                                    <img src={oneZeroImg} alt="OneZero"/>
                                </Link>
                            </div>
                            <div className="col-12 col-sm-6 col-sp">
                                <Link to="/arbeiten/momo" className="project">
                                    <div className="row">
                                        <div className="col-9">
                                            <h4>
                                                <Translate id="work.overview.current.momo.title"/>
                                            </h4>
                                            <p>
                                                <Translate id="work.overview.current.momo.subTitle"/>
                                            </p>
                                        </div>
                                        <div className="col-3 type">
                                            <div>
                                                <Translate id="work.overview.current.momo.type"
                                                           options={{renderInnerHtml: true}}/>
                                            </div>
                                        </div>
                                    </div>
                                    <img src={momoImg} alt="MOMO"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4">
                        <div className="row flex-wrap">
                            <div className="col-12 col-sm-6 col-sp">
                                <Link to="/arbeiten/mandy-robotersimulation" className="project">
                                    <div className="row">
                                        <div className="col-9">
                                            <h4>
                                                <Translate id="work.overview.current.mandy.title"/>
                                            </h4>
                                            <p>
                                                <Translate id="work.overview.current.mandy.subTitle"/>
                                            </p>
                                        </div>
                                        <div className="col-3 type">
                                            <div>
                                                <Translate id="work.overview.current.mandy.type"
                                                           options={{renderInnerHtml: true}}/>
                                            </div>
                                        </div>
                                    </div>
                                    <img src={mandyImg} alt="ManDy"/>
                                </Link>
                            </div>
                            <div className="col-12 col-sm-6 col-sp">
                                <Link to="/arbeiten/brlo-brwhouse" className="project">
                                    <div className="row">
                                        <div className="col-9">
                                            <h4>
                                                <Translate id="work.overview.current.brlo.title"/>
                                            </h4>
                                            <p>
                                                <Translate id="work.overview.current.brlo.subTitle"/>
                                            </p>
                                        </div>
                                        <div className="col-3 type">
                                            <div>
                                                <Translate id="work.overview.current.brlo.type"
                                                           options={{renderInnerHtml: true}}/>
                                            </div>
                                        </div>
                                    </div>
                                    <img src={brloImg} alt="BRLO"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4">
                        <div className="row flex-wrap">
                            {/*<div className="col-12 col-sm-6 col-sp">
                                <Link to="/arbeiten/subbotnik" className="project">
                                    <div className="row">
                                        <div className="col-9">
                                            <h4>
                                                <Translate id="work.overview.current.kuko.title"/>
                                            </h4>
                                            <p>
                                                <Translate id="work.overview.current.kuko.subTitle"/>
                                            </p>
                                        </div>
                                        <div className="col-3 type">
                                            <div>
                                                <Translate id="work.overview.current.kuko.type"
                                                           options={{renderInnerHtml: true}}/>
                                            </div>
                                        </div>
                                    </div>
                                    <img src={kukoImg} alt="Subbotnik"/>
                                </Link>
                            </div>*/}
                            <div className="col-12 col-sm-6 col-sp">
                                <Link to="/arbeiten/energiewende" className="project">
                                    <div className="row">
                                        <div className="col-9">
                                            <h4>
                                                <Translate id="work.overview.current.energie.title"/>
                                            </h4>
                                            <p>
                                                <Translate id="work.overview.current.energie.subTitle"/>
                                            </p>
                                        </div>
                                        <div className="col-3 type">
                                            <div>
                                                <Translate id="work.overview.current.energie.type"
                                                           options={{renderInnerHtml: true}}/>
                                            </div>
                                        </div>
                                    </div>
                                    <img src={energieImg} alt="Energiewende"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Work;
