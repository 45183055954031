import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import homepage from './store/reducer/index';

const store = createStore(
    homepage,
    applyMiddleware(thunk)
);

export {store}
