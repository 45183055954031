import React, {Fragment} from 'react';
import {Translate} from "react-localize-redux";
import {connect} from "react-redux";
import {setCareAboutBottomLine, setCareAboutBottomLineOriginals} from "../store/actions";

class ProjectPreviewContainer extends React.Component {

    state = {
        careThisLayout: false
    };

    componentDidMount() {
        window.addEventListener('resize', this.setOriginals);
        window.addEventListener('scroll', this.checkCareAboutBottomLine);
        window.setTimeout(this.setOriginals, 100);
    }

    setOriginals = () => {
        this.props.setCareAboutBottomLine({});
        if(getComputedStyle(document.getElementById('mainMenu')).bottom === '0px') {
            this.props.setCareAboutBottomLine({});
            this.setState({careThisLayout: false});
        } else {
            this.props.setCareAboutBottomLineOriginals({
                mainMenu: document.getElementById('mainMenu').getBoundingClientRect().top,
                backToWorkButton: document.getElementById('backToWorkButton').getBoundingClientRect().top,
                logoContainer: document.getElementById('logoContainer').getBoundingClientRect().top
            });
            this.setState({careThisLayout: true});
            this.checkCareAboutBottomLine();
        }
    };

    checkCareAboutBottomLine = () => {
        if(this.state.careThisLayout) {
            let spaceBetween = 40;
            let blEl = document.getElementById('projectPreviewContainer');
            let blTop = blEl.getBoundingClientRect().top - spaceBetween; // why spaceBetween?!
            let mmEl = document.getElementById('mainMenu');
            let mmBottom = mmEl.getBoundingClientRect().top + mmEl.getBoundingClientRect().height;
            let newMMTop = blEl.offsetTop - mmEl.getBoundingClientRect().height - spaceBetween;
            if(blTop - mmBottom < spaceBetween + 1 && newMMTop - document.documentElement.scrollTop + 1 < this.props.navigation.careAboutBottomLineOriginals.mainMenu) {
                let mmTop = mmEl.getBoundingClientRect().top;
                let btwbEl = document.getElementById('backToWorkButton');
                let btwbBottom = btwbEl.getBoundingClientRect().top + btwbEl.getBoundingClientRect().height;
                let newBtwbTop = newMMTop - btwbEl.getBoundingClientRect().height - spaceBetween;
                if(mmTop - btwbBottom < spaceBetween + 1 && newBtwbTop - document.documentElement.scrollTop + 1 < this.props.navigation.careAboutBottomLineOriginals.backToWorkButton) {
                    let btwbTop = btwbEl.getBoundingClientRect().top;
                    let lcEl = document.getElementById('logoContainer');
                    let lcBottom = lcEl.getBoundingClientRect().top + lcEl.getBoundingClientRect().height;
                    let newLcTop = newBtwbTop - lcEl.getBoundingClientRect().height - spaceBetween;
                    if(btwbTop - lcBottom < spaceBetween + 1 && newLcTop - document.documentElement.scrollTop + 1 < this.props.navigation.careAboutBottomLineOriginals.logoContainer) {
                        this.props.setCareAboutBottomLine({mainMenu: newMMTop, backToWorkButton: newBtwbTop, logoContainer: newLcTop});
                    } else {
                        this.props.setCareAboutBottomLine({mainMenu: newMMTop, backToWorkButton: newBtwbTop});
                    }
                } else {
                    this.props.setCareAboutBottomLine({mainMenu: newMMTop});
                }
            } else {
                this.props.setCareAboutBottomLine({});
            }
        }
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.setOriginals);
        window.removeEventListener('scroll', this.checkCareAboutBottomLine);
        this.props.setCareAboutBottomLine({});
    }

    render() {
        return (
            <div className="ProjectPreviewContainer row flex-wrap" id="projectPreviewContainer">
                <div className="container-title-row col-12">
                    <h3 className="d-none d-lg-block"><Translate id="work.previews.title.long"/></h3>
                    <h3 className="d-lg-none"><Translate id="work.previews.title.short"/></h3>
                </div>
                <Fragment>
                    {this.props.children}
                </Fragment>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    navigation: state.navigation
});

export default connect(mapStateToProps, {
    setCareAboutBottomLine, setCareAboutBottomLineOriginals
})(ProjectPreviewContainer);
