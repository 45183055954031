import React from 'react';
import {Translate} from "react-localize-redux";
import Button from "../../elements/Button";

class Imprint extends React.Component {
    render() {
        return (
            <div className="Imprint container-semi-fluid">
                <div className="row flex-wrap">
                    <div className="col-12 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4 intro-col">
                        <Translate id="imprint.intro" options={{renderInnerHtml: true}}/>
                    </div>
                    <div className="col-12 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-4 offset-lg-4 address-col">
                        <Translate id="imprint.address" options={{renderInnerHtml: true}}/>
                    </div>
                    <div className="d-none d-lg-block col-lg-4 info1-col-lg info-col">
                        <Translate id="imprint.info1" options={{renderInnerHtml: true}}/>
                    </div>
                    <div className="col-12 col-sm-9 offset-sm-3 col-md-7 offset-md-0 col-lg-4 offset-lg-4 text-center mail-col">
                        <Button asLink block to={<Translate id="imprint.mail.link"/>} title={<Translate id="imprint.mail.title"/>}/>
                        <div className="button-info">
                            <Translate id="imprint.mail.address"/>
                        </div>
                    </div>
                    <div className="col-12 col-sm-9 offset-sm-3 col-md-7 offset-md-0 col-lg-4 offset-lg-0 text-center phone-col">
                        <Button asLink block to={<Translate id="imprint.phone.link"/>} title={<Translate id="imprint.phone.title"/>}/>
                        <div className="button-info">
                            <Translate id="imprint.phone.number"/>
                        </div>
                    </div>
                    <div className="col-12 col-sm-9 offset-sm-3 col-md-12 offset-md-0 d-lg-none info1-col info-col">
                        <Translate id="imprint.info1" options={{renderInnerHtml: true}}/>
                    </div>
                    <div className="col-12 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4 parts-col">
                        <div className="part">
                            <h4><Translate id="imprint.part1.title"/></h4>
                            <div className="text">
                                <Translate id="imprint.part1.text" options={{renderInnerHtml: true}}/>
                            </div>
                        </div>
                        <div className="part">
                            <h4><Translate id="imprint.part2.title"/></h4>
                            <div className="text">
                                <Translate id="imprint.part2.text" options={{renderInnerHtml: true}}/>
                            </div>
                        </div>
                        <div className="part">
                            <h4><Translate id="imprint.part3.title"/></h4>
                            <div className="text">
                                <Translate id="imprint.part3.text" options={{renderInnerHtml: true}}/>
                            </div>
                        </div>
                        <div className="part">
                            <h4><Translate id="imprint.part4.title"/></h4>
                            <div className="text">
                                <Translate id="imprint.part4.text" options={{renderInnerHtml: true}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Imprint;
