import React from 'react';
import {Translate} from "react-localize-redux";
import schlemmer1Img from '../../gfx/work/schlemmer/schlemmer1.jpg';
import schlemmer2Img from '../../gfx/work/schlemmer/schlemmer2.jpg';
import schlemmer3Img from '../../gfx/work/schlemmer/schlemmer3.jpg';
import schlemmer4Img from '../../gfx/work/schlemmer/schlemmer4.jpg';
import schlemmer5Img from '../../gfx/work/schlemmer/schlemmer5.jpg';
import schlemmer6Img from '../../gfx/work/schlemmer/schlemmer6.jpg';
import schlemmer7Img from '../../gfx/work/schlemmer/schlemmer7.jpg';
import WorkProjectFirstElement from "../../elements/WorkProjectFirstElement";
import ProjectPreviewContainer from "../../elements/ProjectPreviewContainer";
import ProjectPreview from "../../elements/ProjectPreview";

class Schlemmer extends React.Component {
    render() {
        return (
            <div className="schlemmer workProject container-semi-fluid">
                <div className="row workProject-header flex-wrap">
                    <div
                        className="col-11 offset-0 col-sm-4 offset-sm-3 col-md-6 offset-md-0 col-lg-4 offset-lg-4 client-col">
                        <h5>
                            <Translate id="work.client"/>
                        </h5>
                        <p>
                            <Translate id="work.schlemmer.client"/>
                        </p>
                        <p className="partners">
                            <Translate id="work.schlemmer.partners"/>
                        </p>
                    </div>
                    <div className="col-12 d-sm-none spacer client-project-spacer"/>
                    <div className="col-11 col-sm-4 offset-sm-1 col-md-6 offset-md-0 col-lg-4 offset-lg-0 project-col">
                        <h5>
                            <Translate id="work.project"/>
                        </h5>
                        <p>
                            <Translate id="work.schlemmer.project"/>
                        </p>
                    </div>
                </div>
                <div className="row workProject-content flex-wrap">
                    <WorkProjectFirstElement className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4">
                        <img src={schlemmer1Img} alt=""/>
                    </WorkProjectFirstElement>
                    <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-lg-6 offset-lg-6 text">
                        <div className="row">
                            <div className="col-12 col-sm-6 offset-sm-6 col-md-12 offset-md-0 text-col">
                                <Translate id="work.schlemmer.text1" options={{renderInnerHtml: true}}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-9 offset-sm-3 col-md-12 offset-md-0">
                        <div className="row">
                            <div className="col-6 offset-0 col-lg-4 offset-lg-4">
                                <img src={schlemmer2Img} alt=""/>
                            </div>
                            <div className="col-6 col-lg-4">
                                <img src={schlemmer3Img} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 spacer"/>
                    <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4">
                        <img src={schlemmer4Img} alt=""/>
                    </div>
                    <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-lg-6 offset-lg-6 text">
                        <div className="row">
                            <div className="col-12 col-sm-6 offset-sm-6 col-md-12 offset-md-0 text-col">
                                <Translate id="work.schlemmer.text2" options={{renderInnerHtml: true}}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-9 offset-sm-3 col-md-12 offset-md-0">
                        <div className="row">
                            <div className="col-6 offset-0 col-lg-4 offset-lg-4">
                                <img src={schlemmer5Img} alt=""/>
                            </div>
                            <div className="col-6 col-lg-4">
                                <img src={schlemmer6Img} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 spacer"/>
                    <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4">
                        <img src={schlemmer7Img} alt=""/>
                    </div>
                    <div
                        className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4 credits">
                        <strong>
                            <Translate id="work.photoCredit"/>&nbsp;
                        </strong>
                        <Translate id="work.schlemmer.photoCredit"/>
                    </div>
                </div>
                <ProjectPreviewContainer>
                    <ProjectPreview projectKey="energie" link="/arbeiten/energiewende"/>
                    <ProjectPreview projectKey="kuko" link="/arbeiten/subbotnik"/>
                    {/*<ProjectPreview projectKey="edeka" link="/arbeiten/edeka"/>*/}
                    <ProjectPreview projectKey="brlo" link="/arbeiten/brlo-brwhouse"/>
                </ProjectPreviewContainer>
            </div>
        )
    }
}

export default Schlemmer;
