import React from 'react';
import referenceLogos01 from '../../gfx/agentur_reference_logos_01.png';
import referenceLogos01Mobile from '../../gfx/agentur_reference_logos_01_mobile.png';
import referenceLogos02 from '../../gfx/agentur_reference_logos_02.png';
import referenceLogos02Mobile from '../../gfx/agentur_reference_logos_02_mobile.png';
import processImage from '../../gfx/agentur_process.jpg';
import Button from "../../elements/Button";
import {Translate} from "react-localize-redux";

class AgenturInfoTabs extends React.Component {

    state = {
        selectedKey: 0,
        hoverKey: 0,
        activeLineLeft: 0,
        activeLineWidth: 100,
        isAnimatingContent: false,
        waitingSelectionKey: false,
        withContentAnimation: true,
        centerHasNoWidth: false
    };

    buttonRefs = [React.createRef(), React.createRef(), React.createRef()];

    componentDidMount() {
        this.calculateActiveLine();
        this.buttonRefs.forEach((buttonRef, i) => {
            buttonRef.current.addEventListener('mouseenter', () => this.handleButtonMouseEnter(i));
            buttonRef.current.addEventListener('mouseleave', () => this.handleButtonMouseLeave(i));
        });
    }

    componentWillUnmount() {
        this.buttonRefs.forEach((buttonRef, i) => {
            buttonRef.current.removeEventListener('mouseenter', () => this.handleButtonMouseEnter(i));
            buttonRef.current.removeEventListener('mouseleave', () => this.handleButtonMouseLeave(i));
        });
    }

    handleButtonMouseEnter = (i) => {
        this.setState({hoverKey: i});
        this.calculateActiveLine();
    };

    handleButtonMouseLeave = (i) => {
        window.setTimeout(() => {
            if (this.state.hoverKey === i) {
                this.setState({hoverKey: this.state.selectedKey});
                this.calculateActiveLine();
            }
        }, 100);
    };

    calculateActiveLine = () => {
        this.setState({
            activeLineLeft: this.buttonRefs[this.state.hoverKey].current.offsetLeft,
            activeLineWidth: this.buttonRefs[this.state.hoverKey].current.offsetWidth
        });
    };

    setActiveTab = (key) => {
        if (key !== this.state.selectedKey) {
            if (!this.state.isAnimatingContent) {
                let releaseCenterHasNoWidthOnFinish = false;
                if (Math.abs(this.state.selectedKey - key) > 1) {
                    this.setCenterNoWidth();
                    releaseCenterHasNoWidthOnFinish = true;
                }
                window.setTimeout(() => {
                    this.setState({
                        selectedKey: key,
                        isAnimatingContent: true
                    });
                    window.setTimeout(() => {
                        this.setState({isAnimatingContent: false});
                        window.setTimeout(() => {
                            if (releaseCenterHasNoWidthOnFinish) {
                                this.releaseCenterNoWidth();
                            }
                            if (this.state.waitingSelectionKey) {
                                let newKey = this.state.waitingSelectionKey;
                                this.setState({waitingSelectionKey: false, hoverKey: newKey});
                                this.setActiveTab(newKey);
                            }
                        }, 100);
                    }, 1000);
                }, 110);
            } else {
                this.setState({
                    waitingSelectionKey: key
                });
            }
        }
    };

    setCenterNoWidth = () => {
        this.setState({
            withContentAnimation: false,
            centerHasNoWidth: true
        });
        window.setTimeout(() => {
            this.setState({
                withContentAnimation: true
            });
        }, 100);
    };

    releaseCenterNoWidth = () => {
        this.setState({
            withContentAnimation: false,
            centerHasNoWidth: false
        });
        window.setTimeout(() => {
            this.setState({
                withContentAnimation: true
            });
        }, 100);


    };

    render() {
        return (
            <div className="AgenturInfoTabs" id="blackTopElement">
                <div className="container-semi-fluid agentur-info-container">
                    <div className="row">
                        <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-4">
                            <div className="headline d-block d-lg-inline-block">
                                <div className="active-line"
                                     style={{left: this.state.activeLineLeft, width: this.state.activeLineWidth}}/>
                                <div className="items">
                                    <button className={`item${this.state.selectedKey === 0 ? ' selected' : ''}`}
                                            ref={this.buttonRefs[0]} onClick={() => this.setActiveTab(0)}>
                                        <div className="d-none d-md-inline">
                                            <Translate id="agentur.info.spirit.title.oneLine"/>
                                        </div>
                                        <div className="d-inline d-md-none">
                                            <Translate id="agentur.info.spirit.title.twoLine" options={{renderInnerHtml: true}}/>
                                        </div>
                                    </button>
                                    <button className={`item${this.state.selectedKey === 1 ? ' selected' : ''}`}
                                            ref={this.buttonRefs[1]} onClick={() => this.setActiveTab(1)}>
                                        <div className="d-none d-md-inline">
                                            <Translate id="agentur.info.engagement.title.oneLine"/>
                                        </div>
                                        <div className="d-inline d-md-none">
                                            <Translate id="agentur.info.engagement.title.twoLine" options={{renderInnerHtml: true}}/>
                                        </div>
                                    </button>
                                    <button className={`item${this.state.selectedKey === 2 ? ' selected' : ''}`}
                                            ref={this.buttonRefs[2]} onClick={() => this.setActiveTab(2)}>
                                        <div className="d-none d-md-inline">
                                            <Translate id="agentur.info.process.title.oneLine"/>
                                        </div>
                                        <div className="d-inline d-md-none">
                                            <Translate id="agentur.info.process.title.twoLine" options={{renderInnerHtml: true}}/>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row row-content">
                        <div className="col-12 offset-0 col-sm-9 offset-sm-3 col-md-12 offset-md-0 col-lg-7 offset-lg-4">
                            <div className="agentur-content-container">
                                <div
                                    className={`agentur-inner-container${this.state.withContentAnimation ? ' withAnimation' : ''}`}
                                    style={{left: `calc(${(0 - (this.state.selectedKey === 2 && this.state.centerHasNoWidth ? 1 : this.state.selectedKey)) * 100}% - ${(this.state.selectedKey === 2 && this.state.centerHasNoWidth ? 1 : this.state.selectedKey) * 80}px)`}}>
                                    <div className="agentur-content">
                                        <div className="row">
                                            <div className="col-12 offset-0 col-md-8 offset-md-2 col-lg-12 offset-lg-0">
                                                <h3><Translate id="agentur.info.spirit.headline" options={{renderInnerHtml: true}}/></h3>
                                                <div className="content">
                                                    <Translate id="agentur.info.spirit.text" options={{renderInnerHtml: true}}/>
                                                </div>
                                                <Button white asLink to="/arbeiten" onBlackBg title={<Translate id="agentur.info.spirit.cta"/>}/>
                                            </div>
                                        </div>
                                        <img className="referenceLogos d-none d-sm-block" src={referenceLogos01} alt=""/>
                                        <img className="referenceLogos d-block d-sm-none" src={referenceLogos01Mobile} alt=""/>
                                    </div>
                                    <div className={`agentur-content${this.state.centerHasNoWidth ? ' no-width' : ''}`}>
                                        <div className="row">
                                            <div className="col-12 offset-0 col-md-8 offset-md-2 col-lg-12 offset-lg-0">
                                                <h3><Translate id="agentur.info.engagement.headline" options={{renderInnerHtml: true}}/></h3>
                                                <div className="content">
                                                    <Translate id="agentur.info.engagement.text" options={{renderInnerHtml: true}}/>
                                                </div>
                                            </div>
                                        </div>
                                        <img className="referenceLogos d-none d-sm-block" src={referenceLogos02} alt=""/>
                                        <img className="referenceLogos d-block d-sm-none" src={referenceLogos02Mobile} alt=""/>
                                    </div>
                                    <div className="agentur-content">
                                        <div className="row">
                                            <div className="col-12 offset-0 col-md-8 offset-md-2 col-lg-12 offset-lg-0">
                                                <h3><Translate id="agentur.info.process.headline" options={{renderInnerHtml: true}}/></h3>
                                                <div className="content">
                                                    <Translate id="agentur.info.process.text" options={{renderInnerHtml: true}}/>
                                                </div>
                                            </div>
                                        </div>
                                        <img className="referenceLogos smaller" src={processImage} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AgenturInfoTabs;
