import React, {Fragment} from 'react';
import MainMenu from "../../elements/MainMenu";
import LogoContainer from "../../elements/LogoContainer";
import AgenturInfoTabs from "./AgenturInfoTabs";
import FullSizeText from "../../elements/FullSizeText";
import Persons from "./Persons";
import Jobs from "./Jobs";
import {connect} from "react-redux";
import {Translate} from "react-localize-redux";

class Agentur extends React.Component {

    state = {
        personsClaimLeft: 0, //0.33,
        personsMarginTop: '-1.3vw'
    };

    componentDidMount() {
        document.body.classList.remove('logoTextHidden');
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        document.body.classList.add('logoTextHidden');
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        /*let element = document.getElementById('personsClaim');
        if(element) {
            let ePositionTop = element.getBoundingClientRect().top;
            let windowHeight = window.innerHeight;
            let ePosition = ePositionTop - windowHeight;
            let newPersonsClaimLeft = 0.4;
            if(ePosition > 0) {
                newPersonsClaimLeft = 0.4;
            } else if (ePosition * -1 > windowHeight / 2) {
                newPersonsClaimLeft = 0;
            } else {
                newPersonsClaimLeft = 0.4 * (ePositionTop - windowHeight/2) / (windowHeight / 2);
            }
            this.setState({personsClaimLeft: newPersonsClaimLeft});
        }*/
        if(window.scrollY > 30) {
            document.body.classList.add('logoTextHidden');
        } else {
            document.body.classList.remove('logoTextHidden');
        }
    };

    render() {
        return (
            <Fragment>
                <div className={`bg-black-container${this.props.navigation.overlayOpacity === 0 ? ' out' : ' in'}`}>
                    <div className="bg-black-helper">
                        <MainMenu white/>
                        <LogoContainer isHelper white/>
                    </div>
                    <AgenturInfoTabs/>
                </div>
                <div className={`bg-black-container zindex-unset${this.props.navigation.overlayOpacity === 0 ? ' out' : ' in'}`}>
                    <div className="bg-black-helper">
                        <MainMenu white/>
                        <LogoContainer white/>
                    </div>
                    <div className="container-semi-fluid personsClaim-container" id="personsClaim" style={{transform: `translateX(${this.state.personsClaimLeft * 100}%)`}}>
                        <div className="row">
                            <FullSizeText className="col-11" widthOnly onFinish={(fontSize) => this.setState({personsMarginTop: fontSize * -0.39})}>
                                <h2 className="personsClaim">
                                    <Translate id="agentur.crew.title.part1"/><br className="d-inline-block d-sm-none d-md-inline-block d-lg-none"/><span className="d-none d-sm-inline d-md-none d-lg-inline">&nbsp;</span>
                                    <Translate id="agentur.crew.title.part2"/>
                                </h2>
                            </FullSizeText>
                        </div>
                    </div>
                </div>
                <Persons marginTop={this.state.personsMarginTop}/>
                <Jobs/>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    navigation: state.navigation
});

export default connect(mapStateToProps, {
})(Agentur);
