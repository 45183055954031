import {
    NAVIGATION_SET_CARE_ABOUT_BOTTOM_LINE, NAVIGATION_SET_CARE_ABOUT_BOTTOM_LINE_ORIGINALS,
    NAVIGATION_SET_CONTENT,
    NAVIGATION_SET_IS_SCROLLED,
    NAVIGATION_SET_OVERLAY_OPACITY,
    NAVIGATION_SET_ROUTE,
    NAVIGATION_SET_SHUTTER_DIRECTION, NAVIGATION_SET_WORK_PROJECT_FIRST_ELEMENT_TOP
} from "../../config/actions";

const INIT_STATE = {
    lastRoute: null,
    currentRoute: null,
    lastContent: null,
    currentContent: null,
    isScrolled: false,
    overlayOpacity: 0,
    shutterDirection: null,
    workProjectFirstElementTop: 0,
    careAboutBottomLine: {},
    careAboutBottomLineOriginals: {}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case NAVIGATION_SET_ROUTE: {
            return {
                ...state,
                lastRoute: state.currentRoute,
                currentRoute: action.route
            }
        }
        case NAVIGATION_SET_CONTENT: {
            return {
                ...state,
                lastContent: state.currentContent,
                currentContent: action.content
            }
        }
        case NAVIGATION_SET_IS_SCROLLED: {
            return {
                ...state,
                isScrolled: action.isScrolled
            }
        }
        case NAVIGATION_SET_OVERLAY_OPACITY: {
            return {
                ...state,
                overlayOpacity: action.overlayOpacity
            }
        }
        case NAVIGATION_SET_SHUTTER_DIRECTION: {
            return {
                ...state,
                shutterDirection: action.shutterDirection
            }
        }
        case NAVIGATION_SET_WORK_PROJECT_FIRST_ELEMENT_TOP: {
            return {
                ...state,
                workProjectFirstElementTop: action.workProjectFirstElementTop
            }
        }
        case NAVIGATION_SET_CARE_ABOUT_BOTTOM_LINE: {
            return {
                ...state,
                careAboutBottomLine: action.careAboutBottomLine
            }
        }
        case NAVIGATION_SET_CARE_ABOUT_BOTTOM_LINE_ORIGINALS: {
            return {
                ...state,
                careAboutBottomLineOriginals: action.careAboutBottomLineOriginals
            }
        }
        default:
            return state;
    }
}
