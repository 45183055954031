import React from 'react';
import {NavLink} from "react-router-dom";
import {Translate} from "react-localize-redux";

class FooterMenu extends React.Component {
    render() {
        return (
            <div className={`FooterMenu d-sm-none${this.props.black ? ' black' : ''}`}>
                <div className="container-semi-fluid">
                    <div className="row">
                        <div className="col-6 col-sm-3 offset-sm-3 col-lg-2 offset-lg-8">
                            <NavLink to="/datenschutz" activeClassName="active"><Translate id="footerMenu.privacy"/></NavLink>
                        </div>
                        <div className="col-6 col-sm-3 col-lg-2 text-right">
                            <NavLink to="/impressum" activeClassName="active"><Translate id="footerMenu.imprint"/></NavLink>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FooterMenu;
