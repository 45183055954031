import React from 'react';
import {connect} from "react-redux";
import {setWorkProjectFirstElementTop} from "../store/actions";
import { omit } from 'lodash';

class WorkProjectFirstElement extends React.Component {

    elementId = Math.random().toString(36).substring(7);

    componentDidMount() {
        window.addEventListener('resize', this.setElementTop);
        this.setElementTop();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setElementTop);
    }

    setElementTop = () => {
        this.props.setWorkProjectFirstElementTop(document.getElementById(this.elementId).offsetTop);
    };

    render() {
        const newProps = omit(this.props, 'setWorkProjectFirstElementTop');
        return (
            <div {...newProps} id={this.elementId}>
                {this.props.children}
            </div>
        )
    }
}

export default connect(null, {
    setWorkProjectFirstElementTop
})(WorkProjectFirstElement);
