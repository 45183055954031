import React, {Fragment} from 'react';
import {connect} from "react-redux";

class Shutter extends React.Component {
    render() {
        return (
            <Fragment>
                <div className={`Shutter${this.props.navigation.shutterDirection ? ` ${this.props.navigation.shutterDirection}` : ''}`}/>
                <div className={`Shutter-inner${this.props.navigation.shutterDirection ? ` ${this.props.navigation.shutterDirection}` : ''}`}>
                    {this.props.children}
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    navigation: state.navigation
});

export default connect(mapStateToProps, {
})(Shutter);
