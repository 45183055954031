import React from 'react';
import englishIcon from '../gfx/english.svg';
import englishIconBlack from '../gfx/english_black.svg';
import {NavLink} from "react-router-dom";
import {Translate, withLocalize} from "react-localize-redux";

class SideMenu extends React.Component {
    render() {
        return (
            <div className="SideMenu" style={{opacity: this.props.opacity}}>
                <NavLink to="/datenschutz" className="optional d-none d-sm-block" activeClassName="active"><Translate id="sideMenu.privacy"/></NavLink>
                <NavLink to="/impressum" className="optional d-none d-sm-block" activeClassName="active"><Translate id="sideMenu.imprint"/></NavLink>
                <button onClick={() => this.props.setActiveLanguage(this.props.activeLanguage && this.props.activeLanguage.code === 'de' ? 'en' : 'de')}>
                    <Translate id="sideMenu.otherLanguage"/>
                    <img src={englishIcon} alt="English" className="default"/>
                    <img src={englishIconBlack} alt="English" className="black"/>
                </button>
            </div>
        )
    }
}

export default withLocalize(SideMenu);
