import React, {Fragment} from 'react';
import arrowBlack from "../gfx/arrow-black.svg";
import arrowWhite from "../gfx/arrow-white.svg";
import {Link} from "react-router-dom";

class Button extends React.Component {
    render() {
        return (
            <div
                className={`Button${this.props.className ? ` ${this.props.className}` : ''}${this.props.white ? ' white' : ''}${this.props.onBlackBg ? ' onBlackBg' : ''}${this.props.textLeft ? ' textLeft' : ''}${this.props.outline ? ' outline' : ''}${this.props.arrowDown ? ' arrowDown' : ''}${this.props.hover ? ' hover' : ''}${this.props.block ? ' block' : ''}`}
                style={this.props.style}>
                {this.props.asLink ? (
                    <Fragment>
                        <Link className="btn btn-default" to={this.props.to}>
                            {this.props.withArrow ? (
                                <div className="icon-container">
                                    <img src={this.props.white ? arrowBlack : arrowWhite} alt="" className="arrow"/>
                                </div>
                            ) : null}
                            {this.props.title ? (
                                this.props.title
                            ) : (
                                <Fragment>
                                    {this.props.shortTitle ? (
                                        <span className="d-lg-none">
                                        {this.props.shortTitle}
                                    </span>
                                    ) : null}
                                    {this.props.longTitle ? (
                                        <span className="d-none d-lg-inline">
                                        {this.props.longTitle}
                                    </span>
                                    ) : null}
                                </Fragment>
                            )}
                        </Link>
                        <Link className="btn btn-hover" to={this.props.to}>
                            <div className="overlay-container">
                                <div className="yellow"/>
                                <svg version="1.1" id="Ebene_1" x="0px" y="0px"
                                     viewBox="0 0 29.38 40" style={{enableBackground: 'new 0 0 29.38 40'}}>
                                    <polygon id="Rechteck_1123" className="st0" style={{fill: '#ffff00'}} points="1.16,0 29.38,0 29.38,40 24.56,40 "/>
                                    <polygon points="23.4,40 25.71,40 2.32,0 0,0 " className="st1"/>
                                </svg>
                            </div>
                            <div className="title">
                                {this.props.withArrow ? (
                                    <div className="icon-container">
                                        <img src={arrowBlack} alt="" className="arrow"/>
                                    </div>
                                ) : null}
                                {this.props.title ? (
                                    this.props.title
                                ) : (
                                    <Fragment>
                                        {this.props.shortTitle ? (
                                            <span className="d-lg-none">
                                            {this.props.shortTitle}
                                        </span>
                                        ) : null}
                                        {this.props.longTitle ? (
                                            <span className="d-none d-lg-inline">
                                            {this.props.longTitle}
                                        </span>
                                        ) : null}
                                    </Fragment>
                                )}
                            </div>
                        </Link>
                    </Fragment>
                ) : this.props.asRealLink ? (
                    <Fragment>
                        <a className="btn btn-default" href={this.props.to} target={this.props.target ? this.props.target : '_blank'}>
                            {this.props.withArrow ? (
                                <div className="icon-container">
                                    <img src={this.props.white ? arrowBlack : arrowWhite} alt="" className="arrow"/>
                                </div>
                            ) : null}
                            {this.props.title ? (
                                this.props.title
                            ) : (
                                <Fragment>
                                    {this.props.shortTitle ? (
                                        <span className="d-lg-none">
                                        {this.props.shortTitle}
                                    </span>
                                    ) : null}
                                    {this.props.longTitle ? (
                                        <span className="d-none d-lg-inline">
                                        {this.props.longTitle}
                                    </span>
                                    ) : null}
                                </Fragment>
                            )}
                        </a>
                        <a className="btn btn-hover" href={this.props.to} target={this.props.target ? this.props.target : '_blank'}>
                            <div className="overlay-container">
                                <div className="yellow"/>
                                <svg version="1.1" id="Ebene_1" x="0px" y="0px"
                                     viewBox="0 0 29.38 40" style={{enableBackground: 'new 0 0 29.38 40'}}>
                                    <polygon id="Rechteck_1123" className="st0" style={{fill: '#ffff00'}} points="1.16,0 29.38,0 29.38,40 24.56,40 "/>
                                    <polygon points="23.4,40 25.71,40 2.32,0 0,0 " className="st1"/>
                                </svg>
                            </div>
                            <div className="title">
                                {this.props.withArrow ? (
                                    <div className="icon-container">
                                        <img src={arrowBlack} alt="" className="arrow"/>
                                    </div>
                                ) : null}
                                {this.props.title ? (
                                    this.props.title
                                ) : (
                                    <Fragment>
                                        {this.props.shortTitle ? (
                                            <span className="d-lg-none">
                                            {this.props.shortTitle}
                                        </span>
                                        ) : null}
                                        {this.props.longTitle ? (
                                            <span className="d-none d-lg-inline">
                                            {this.props.longTitle}
                                        </span>
                                        ) : null}
                                    </Fragment>
                                )}
                            </div>
                        </a>
                    </Fragment>
                ) : (
                    <Fragment>
                        <button className="btn btn-default" onClick={this.props.onClick}>
                            {this.props.withArrow ? (
                                <div className="icon-container">
                                    <img src={this.props.white ? arrowBlack : arrowWhite} alt="" className="arrow"/>
                                </div>
                            ) : null}
                            {this.props.title ? (
                                this.props.title
                            ) : (
                                <Fragment>
                                    {this.props.shortTitle ? (
                                        <span className="d-lg-none">
                                        {this.props.shortTitle}
                                    </span>
                                    ) : null}
                                    {this.props.longTitle ? (
                                        <span className="d-none d-lg-inline">
                                        {this.props.longTitle}
                                    </span>
                                    ) : null}
                                </Fragment>
                            )}
                        </button>
                        <button className="btn btn-hover" onClick={this.props.onClick}>
                            <div className="overlay-container">
                                <div className="yellow"/>
                                <svg version="1.1" id="Ebene_1" x="0px" y="0px"
                                     viewBox="0 0 29.38 40" style={{enableBackground: 'new 0 0 29.38 40'}}>
                                    <polygon id="Rechteck_1123" className="st0" style={{fill: '#ffff00'}} points="1.16,0 29.38,0 29.38,40 24.56,40 "/>
                                    <polygon points="23.4,40 25.71,40 2.32,0 0,0 " className="st1"/>
                                </svg>
                            </div>
                            <div className="title">
                                {this.props.withArrow ? (
                                    <div className="icon-container">
                                        <img src={arrowBlack} alt="" className="arrow"/>
                                    </div>
                                ) : null}
                                {this.props.title ? (
                                    this.props.title
                                ) : (
                                    <Fragment>
                                        {this.props.shortTitle ? (
                                            <span className="d-lg-none">
                                            {this.props.shortTitle}
                                        </span>
                                        ) : null}
                                        {this.props.longTitle ? (
                                            <span className="d-none d-lg-inline">
                                            {this.props.longTitle}
                                        </span>
                                        ) : null}
                                    </Fragment>
                                )}
                            </div>
                        </button>
                    </Fragment>
                )}
            </div>
        )
    }
}

export default Button;
